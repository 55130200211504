import { httpGet, httpPost } from "../utils/httpAPI"
import { PageType } from "../types/PageType"

export type SettlementListSearchParams = {
  orderId?:string;
  userId?:number;
  confirmStatus?:'대기'|'승인'
  page?:number;
  size?:number;
  sort?:'confirmedAt,desc',
  productId?:number,
}
export const getSettlementList = (params:SettlementListSearchParams)=>{
  return httpGet<PageType<{
    orderId:string;
    userId:number;
    confirmStatus:'대기'|'승인';
    settlementStatus:'지급 대기' | '지급 처리중';
    productPrice:number;
    amount:number;
    feeAmount:number;
    payoutDate:string; // 지급 요청일
    confirmedAt:string; //지급 승인일
    product:{
      id:number;
      title:string;
      titleSecondary:string
    }
  }>>('/settlements', params)
}

export const approveSettlementPayment = (sellOrderId:string)=>{

  return httpPost<{
    orderId:string;
    userId:number;
    confirmStatus:'승인';
    settlementStatus:'지급 대기';
    productPrice:number;
    amount:number;
    feeAmount:number;
    confirmedAt:string
  }>(`/settlements/${sellOrderId}/confirm`)
}

