import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import "App.css"
import { toDateStr } from "utils/datetimeUtil"
import { ProductConditionCode } from "types/DiagnosisType"
import { numberWithCommas, toRange, truncateWithCommas } from "utils/NumberUtils"

import { isNaN } from "lodash"
import QuickMemoButton from "../../components/QuickMemo/QuickMemoButton"
import { UserMemoTargetTypeCode } from "../../types/UserType"
import { getSellOrder } from "../../apis/sellOrderAPI"
import { getProduct } from "../../apis/productAPI"
import { useBeforeDiagnosisProductData } from "./useBeforeDiagnosisProductData"
import ProductPriceHistoryPopup from "../PreProduct/ProductPriceHistoryPopup"
import BeforeDiagnosisProductGallery from "../SellOrder/BeforeDiagnosis/BeforeDiagnosisProductGallery"
import SellOrderInformation from "../PreProduct/EditPopup/SellOrderInformation"
import { BrandType } from "../../types/BrandType"
import { getBrand } from "../../apis/brandAPI"

type BeforeDiagnosisProductPopupProps = {
  open: boolean
  selectedProductId?: number
  handleClose: Function
}

const BeforeDiagnosisProductPopup: React.FC<BeforeDiagnosisProductPopupProps> = ({
  open,
  selectedProductId,
  handleClose,
}) => {
  const [brand, setBrand] = useState<BrandType | undefined>()
  const [sellOrder, setSellOrder] = useState<SellOrderType | undefined>()
  const [priceHistoryPopup, setPriceHistoryPopup] = useState(false)

  const {
    product,
    setProduct,
    changeProductPrice,
    saving,
    estimatedPrice,
    penalty,
    editing,
    setEditing,
    setProductPrice,
    productPrice,
    updateOnDisplay,
  } = useBeforeDiagnosisProductData({
    productId: selectedProductId,
    handleClose,
  })

  useEffect(() => {
    if (open) {
      selectedProductId &&
        getProduct(selectedProductId).then((res) => {
          setProduct(res)
          getBrand(res?.brandSid || "ETC").then(setBrand)
          res.sellOrder &&
            getSellOrder(res.sellOrder.id!).then((res) => {
              setSellOrder(res)
            })
        })
    } else {
      setSellOrder(undefined)
    }
  }, [open, selectedProductId])

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <ProductPriceHistoryPopup
        open={priceHistoryPopup}
        productId={product?.id}
        handleClose={() => {
          setPriceHistoryPopup(false)
        }}
      />
      <DialogTitle>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td colSpan={3} className={"no-border ta-left"}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  <Typography fontWeight={"bold"} fontSize={"30px"} alignItems={"center"}>
                    상품 정보
                  </Typography>
                  <QuickMemoButton target={UserMemoTargetTypeCode.PRODUCT} targetId={product?.id} small />
                </Stack>
              </td>
              <td className={"header"}>상품번호</td>
              <td className={"header"}>상품화</td>
              <td className={"header"}>상품 위치</td>
              <td className={"header"}>노출 상태</td>
              <td className={"header"}>판매 상태</td>
            </tr>
            <tr>
              <td colSpan={3} className={"no-border"}></td>
              <td>
                {product?.id}
                <QuickMemoButton target={UserMemoTargetTypeCode.PRODUCT} targetId={product?.id} small />
              </td>
              <td>{!!product?.completeSetupAt ? "완료" : "미완료"}</td>
              <td>{product.location || "-"}</td>
              <td>
                <Switch
                  checked={product?.onDisplay}
                  color={"success"}
                  onChange={(event, checked) => {
                    setProduct((prevProduct) => ({ ...prevProduct, onDisplay: checked }))
                    updateOnDisplay(checked)
                  }}
                />
              </td>
              <td className={"bold"}>{saving ? "저장중..." : sellOrder?.sellOrderStatus}</td>
            </tr>
          </tbody>
        </table>
      </DialogTitle>
      <DialogContent>
        <SellOrderInformation sellOrder={sellOrder} />

        <h2>상품상태</h2>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"}>제품상태</td>
              <td className={"header"}>보증서</td>
              <td className={"header"}>정품박스</td>
              <td className={"header"}>부속품</td>
              <td className={"header"}>스탬핑 연도</td>
            </tr>
            <tr>
              <td>{sellOrder?.product?.sellerProvidedProductInfo?.productCondition}</td>
              <td>{sellOrder?.product?.sellerProvidedProductInfo?.officialGuarantee}</td>
              <td>{sellOrder?.product?.sellerProvidedProductInfo?.originalBoxCondition}</td>
              <td>{sellOrder?.product?.sellerProvidedProductInfo?.accessoriesCondition}</td>
              <td>{sellOrder?.product?.sellerProvidedProductInfo?.stampingYear}</td>
            </tr>
            {sellOrder?.product?.sellerProvidedProductInfo?.productCondition !== ProductConditionCode.미착용 && (
              <>
                <tr>
                  <td className={"header"} colSpan={3}>
                    상세 부속품
                  </td>
                  <td className={"header"} colSpan={2}>
                    자가진단
                  </td>
                </tr>
                <tr>
                  <td rowSpan={4} colSpan={3}>
                    {brand?.accessories?.map(({ key, value }) => (
                      <FormControlLabel
                        label={value}
                        style={{ marginRight: "30px" }}
                        disabled={true}
                        checked={!!sellOrder?.product?.sellerProvidedProductInfo?.accessories?.[key]}
                        control={<Checkbox />}
                      />
                    ))}
                  </td>
                  <td>찍힘/눌림</td>
                  <td>{sellOrder?.product?.sellerProvidedProductInfo?.selfCheck?.stab || "-"}</td>
                </tr>
                <tr>
                  <td>글라스 흠집</td>
                  <td>{sellOrder?.product?.sellerProvidedProductInfo?.selfCheck?.glassScratch || "-"}</td>
                </tr>
                <tr>
                  <td>링크</td>
                  <td>{sellOrder?.product?.sellerProvidedProductInfo?.selfCheck?.braceletLinks || "-"}</td>
                </tr>
                <tr>
                  <td>보호 필름</td>
                  <td>{sellOrder?.product?.sellerProvidedProductInfo?.selfCheck?.protectingFilm || "-"}</td>
                </tr>
              </>
            )}
            <tr>
              <td colSpan={5} className={"header"}>
                사진 ({sellOrder?.product?.gallery?.length || 0} 개)
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <BeforeDiagnosisProductGallery
                  sellOrder={sellOrder}
                  editable={false}
                  setEditable={() => {}}
                  updateRepresentativeImageByGalleryIndex={() => {}}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={5} className={"header"}>
                설명 ({sellOrder?.product?.userDescription?.length || 0} 자)
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <TextField fullWidth multiline minRows={5} value={sellOrder?.product?.userDescription} />
              </td>
            </tr>
          </tbody>
        </table>

        <h2>상품 가격 정보</h2>
        <h3>바이버 인덱스(시세)</h3>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"} colSpan={1}>
                현재 시장 가격범위 (감가 미적용)
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                estimatedPrice?.marketPrice?.min,
                estimatedPrice?.marketPrice?.max,
                4,
                true
              )} 만원`}</td>
              <td className={"header"} colSpan={1}>
                최근 30일간 시세 볼륨
              </td>
              <td className={"ta-center"} colSpan={1}>
                {estimatedPrice.marketVolume}
              </td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                판매신청 당시 예상 가격범위 ({toDateStr(sellOrder?.acceptedAt)})
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.min,
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.max,
                4
              )} 만원`}</td>
              <td className={"header"} colSpan={1}>
                페널티 기준가
              </td>
              <td className={"ta-center"} colSpan={1}>{`${truncateWithCommas(product?.initialPrice, 4)} 만원`}</td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                진단확정 당시 예상 가격범위 ({toDateStr(sellOrder?.completeDiagnosisAt)})
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtDiagnosis?.min,
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtDiagnosis?.max,
                4,
                true
              )} 만원`}</td>
              <td className={"header"}>페널티 금액</td>
              <td>{numberWithCommas(penalty) || "-"} 원</td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                현재 예상 가격범위
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                estimatedPrice?.correctedEstimatedPrice?.min,
                estimatedPrice?.correctedEstimatedPrice?.max,
                4
              )} 만원`}</td>
            </tr>
          </tbody>
        </table>

        <h3>상점 노출 가격</h3>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"}>최적 판매가</td>
              <td colSpan={3}>
                {sellOrder?.productDiagnosis?.viverPrices?.finalPrice
                  ? truncateWithCommas(sellOrder?.productDiagnosis?.viverPrices?.finalPrice, 4) + " 만원"
                  : "-"}
              </td>
            </tr>
            <tr>
              <td className={"header"}>최종 구매가 / 최적 판매가 (%)</td>
              <td>
                {productPrice && sellOrder?.productDiagnosis?.viverPrices?.finalPrice
                  ? ((1 - productPrice / (sellOrder?.productDiagnosis?.viverPrices?.finalPrice || 1)) * 100).toFixed(2)
                  : "-"}{" "}
                %
              </td>
              <td className={"header"}>최종 구매가 (상점 노출가)</td>
              <td>
                {editing ? (
                  <TextField
                    size={"small"}
                    inputMode={"numeric"}
                    value={productPrice || ""}
                    onChange={(event) => {
                      const numberValue = Number(event.target.value)
                      if (isNaN(numberValue)) {
                        return
                      }

                      setProductPrice(numberValue)
                    }}
                    onWheel={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      return
                    }}
                    onKeyDown={(event) => {
                      if (/\d/.test(event.key)) return
                      if ("Backspace" === event.key || "Delete" === event.key) return
                      event.preventDefault()
                    }}
                  />
                ) : (
                  truncateWithCommas(parseInt((product?.price || 0).toString()), 4) + " 만원"
                )}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant={editing ? "contained" : "outlined"}
                  size={"small"}
                  color={"error"}
                  disabled={
                    sellOrder?.sellOrderStatus !== SellOrderStatusCode.진단_완료 &&
                    sellOrder?.sellOrderStatus !== SellOrderStatusCode.판매_대기 &&
                    sellOrder?.sellOrderStatus !== SellOrderStatusCode.판매중
                  }
                  onClick={() => {
                    setEditing(!editing)

                    if (editing) changeProductPrice()
                  }}
                >
                  {editing ? "확인" : "수정"}
                </Button>
                <Button
                  variant={editing ? "contained" : "outlined"}
                  size={"small"}
                  color={"info"}
                  onClick={() => setPriceHistoryPopup(true)}
                >
                  변경이력
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        {/*<Button variant={"contained"} disabled={isEditingDisabled} onClick={() => update(product!!)}>*/}
        {/*  변경내역 저장*/}
        {/*</Button>*/}
        {/*&nbsp;*/}
        <Button color={"error"} onClick={() => handleClose()}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BeforeDiagnosisProductPopup
