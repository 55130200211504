import { completeSetup, getProduct, updateProduct } from "apis/productAPI"
import {
  AccessoriesConditionCode,
  DiagnosisGradeCode,
  OfficialGuaranteeCode,
  ProductConditionCode,
} from "types/DiagnosisType"
import { toast } from "react-toastify"
import { ResellPriceResponse } from "types/EstimationType"
import { useEffect, useState } from "react"
import {
  CurrencyCode,
  ProductStatusCode,
  ProductType,
  defaultImageLabels,
  SaleTypeCode,
  UsedConditionLevelCode,
} from "types/ProductType"
import { getCancelSellOrderPenaltyFee, getResellPrice } from "apis/estimationAPI"
import { updateSellOrder } from "apis/sellOrderAPI"
import { getBrand } from "../../apis/brandAPI"
import { BrandType } from "../../types/BrandType"
import { validateEngDate } from "../../utils/validationUtils"

type PreProductType = Omit<ProductType, "id"> & {
  id?: number
}

const initialProductData: PreProductType = {
  upgradeRequired: [],
  images: [],
  status: ProductStatusCode.결제_대기,
  currency: CurrencyCode.KR,
  saleType: SaleTypeCode.위탁판매,
  detailModel: {},
}

type Props = {
  productId?: number
  handleClose: Function
}

export const useEditPreProductData = ({ productId, handleClose }: Props) => {
  const [brand, setBrand] = useState<BrandType | undefined>()
  const [product, setProduct] = useState<PreProductType>(initialProductData)
  const [productPrice, setProductPrice] = useState<number>(0)
  const [penalty, setPenalty] = useState<number>(0)
  const [estimatedPrice, setEstimatedPrice] = useState<ResellPriceResponse>({})

  const [editing, setEditing] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [formattedDate, setFormattedDate] = useState("")

  const formatDate = (date: Date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  // 상품의 상태에 따라 총평을 작성
  const generateGeneralReview = (product: PreProductType) => {
    let review = ""

    switch (product.exposedProductInfo?.productCondition) {
      case ProductConditionCode.미착용:
        review += `${brand?.koName} 정식 보증서와 구매한 상태의 모든 부속품이 포함되어 있는 착용하지 않은 새 상품입니다.
`
        break
      case ProductConditionCode.중고:
        review += "바이버 엔지니어의 정품 감정과 상태 진단을 통과한 Pre-owned 상품입니다.\n"
        break
    }
    if (product.exposedProductInfo?.productCondition === ProductConditionCode.중고) {
      switch (product.usedCondition) {
        case UsedConditionLevelCode.새상품_수준:
          review += "새 상품 수준의 컨디션으로 사용감을 찾기 어려운 상태입니다.\n"
          break
        case UsedConditionLevelCode.사용감_있음:
          review += "약간의 사용감이 있지만 전반적으로 매우 양호한 상태입니다. \n"
          break
        default:
          break
      }
      switch (product.exposedProductInfo?.officialGuarantee) {
        case OfficialGuaranteeCode.있음:
          review += `${brand?.koName} 정식 보증서가 포함되어 있습니다.\n`
          break
        case OfficialGuaranteeCode.없음:
          review += `${brand?.koName} 정식 보증서가 없는 상품이지만, 바이버 엔지니어의 감정을 통해 정품임을 확인하였습니다.\n`
          break
        default:
          break
      }
      switch (product.exposedProductInfo?.accessoriesCondition) {
        case AccessoriesConditionCode.있음:
          review += `${brand?.koName} 정품 박스 및 모든 부속품을 포함하고 있습니다.\n`
          break
        case AccessoriesConditionCode.없음:
          review += `${brand?.koName} 정품 박스 및 부속품이 포함된 상품이 아니지만, 바이버의 고급 패키지에 안전하게 포장하여 제공합니다.\n`
          break
        case AccessoriesConditionCode.일부파손:
          let elements = []
          product.exposedProductInfo?.accessories?.outerBox || elements.push("외부박스")
          product.exposedProductInfo?.accessories?.innerBox || elements.push("내부박스")
          product.exposedProductInfo?.accessories?.coscCert || elements.push("COSC인증책자")
          product.exposedProductInfo?.accessories?.guaranteeCase || elements.push("보증서케이스")
          product.exposedProductInfo?.accessories?.medal || elements.push("메달")

          // 사용설명서 관련 모든 항목이 누락일 때만 추가
          if (
            !product.exposedProductInfo?.accessories?.userManual &&
            !product.exposedProductInfo?.accessories?.userManualKor &&
            !product.exposedProductInfo?.accessories?.userManualForeign
          ) {
            elements.push("사용설명서")
          }

          if (elements.length > 0) {
            review += elements.join(", ") + " 누락 상태이오니 참고해 주세요.\n"
          }
          break
        default:
          break
      }
    }

    product.upgradeRequired
      ?.filter((it) => it.action)
      ?.forEach((it) => {
        switch (it.label) {
          case "라이트폴리싱":
            review +=
              "해당 상품은 광택 개선 및 스크래치 제거를 위해 바이버 엔지니어의 라이트폴리싱을 진행한 상품입니다.\n"
            break
          case "폴리싱":
            review += "해당 상품은 광택 개선 및 스크래치 제거를 위해 바이버 엔지니어의 폴리싱을 진행한 상품입니다.\n"
            break
          case "오버홀":
            review +=
              "해당 상품은 바이버 엔지니어의 오버홀, 윤활유 주입을 진행하여 최상의 컨디션을 확보한 상품입니다.\n"
            break
        }
      })
    if (product?.usedConditionNote) review += `${product?.usedConditionNote}\n`

    let partConditions: string[] = []
    product.appearanceCondition?.parts?.forEach((it) => {
      if (it.grade !== DiagnosisGradeCode.양호 && it.note) partConditions.push(` - ${it.label}: ${it.note || "-"}\n`)
    })
    product.internalCondition?.parts?.forEach((it) => {
      if (it.grade !== DiagnosisGradeCode.양호 && it.note) partConditions.push(` - ${it.label}: ${it.note || "-"}\n`)
    })

    if (partConditions.length) {
      review +=
        "\n※ 바이버에서는 진단 전문가가 현미경 등을 통해 상품을 진단하며, 육안으로 확인하기 어려운 미세한 사용감도 아래와 같이 상세히 진단하여 알려드리고 있습니다. \n"
      review += partConditions.join("")
    }

    setProduct((prevProduct) => ({ ...prevProduct, generalReview: review }))
    update({ ...product, generalReview: review })
  }

  // 상품 등록 폼에 입력된 값이 유효한지 검증
  const checkRequired = (updated: PreProductType): boolean => {
    if (!updated?.exposedProductInfo?.productCondition) {
      toast.error("제품상태를 선택해주세요")
      return false
    }
    if (!updated?.exposedProductInfo?.officialGuarantee) {
      toast.error("보증서 유무를 선택해주세요")
      return false
    }
    if (!updated?.exposedProductInfo?.accessoriesCondition) {
      toast.error("부속품 상태를 선택해주세요")
      return false
    }

    if (!updated?.exposedProductInfo?.originalBoxCondition) {
      toast.error("정품박스 상태를 선택해주세요")
      return false
    }

    if (
      updated?.appearanceCondition?.parts?.find(
        (it) => it.grade !== DiagnosisGradeCode.양호 && it.grade !== DiagnosisGradeCode.보통
      )
    ) {
      toast.error("외부 컨디션 항목별 상태가 올바르지 않습니다")
      return false
    }
    if (
      updated?.internalCondition?.parts?.find(
        (it) => it.grade !== DiagnosisGradeCode.양호 && it.grade !== DiagnosisGradeCode.보통
      )
    ) {
      toast.error("내부 컨디션 항목별 상태가 올바르지 않습니다")
      return false
    }
    if (updated?.internalCondition?.parts?.find((it) => !it.measured)) {
      toast.error("내부 컨디션 측정값을 모두 입력해주세요")
      return false
    }
    if (defaultImageLabels.find((required) => !updated?.images?.find((it) => it.required && it.url))) {
      toast.error(`필수 이미지를 모두 첨부해주세요`)
      return false
    }
    if (!updated?.generalReview?.length) {
      toast.error("진단 총평을 작성해주세요.")
      return false
    }

    return true
  }

  const updateOnDisplay = async (onDisplay: boolean) => {
    if (!productId) {
      return
    }

    try {
      setSaving(true)
      await updateProduct(productId, { onDisplay })
      toast.success(onDisplay ? "노출중으로 설정 완료" : "숨김 설정 완료")
    } catch (error: any) {
      toast.error(`변경 실패 (${error.message || error})`)
    } finally {
      setSaving(false)
    }
  }

  // 상품 정보 저장
  const update = async (updatedProduct: PreProductType) => {
    if (!updatedProduct?.id) return

    if (updatedProduct.exposedProductInfo?.officialGuarantee == "있음" && !formattedDate) {
      const stampingUnknownMessage =
        "보증서는 있음으로 설정됐으나, 스탬핑 일자가 입력되지 않았습니다.\n이 경우, 상품 상세에서 스탬핑 일자 ‘알 수 없음’으로 표기됩니다.\n계속하시겠습니까?"
      if (!window.confirm(stampingUnknownMessage)) {
        return
      }
    }

    if (formattedDate && !validateEngDate(formattedDate)) {
      toast.error("스탬핑 일자를 확인해주세요. DD/MM/YYYY 형식으로 입력해야 합니다.")
      return
    }

    if (updatedProduct.status !== ProductStatusCode.판매_대기 && updatedProduct.status !== ProductStatusCode.판매중) {
      toast.error("판매가 완료된 상품은 수정할 수 없습니다")
      return
    }

    const confirmMessage =
      "경고:\n판매중 상품의 정보를 수정하면\n수정 전 정보를 바탕으로 구매를 진행한 고객의\n컴플레인의 대상이 될 수 있습니다."
    if (!window.confirm(confirmMessage)) {
      return
    }

    if (updatedProduct.exposedProductInfo) {
      updatedProduct.exposedProductInfo.stampingDate = formattedDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$2-$1")
    }

    try {
      setSaving(true)
      await updateProduct(updatedProduct.id, updatedProduct)
      toast.success("저장 완료")
    } catch (error: any) {
      toast.error(`저장 실패 (${error.message || error})`)
    } finally {
      setSaving(false)
    }
  }

  // 판매가 변경
  const changeProductPrice = async () => {
    if (product.status !== ProductStatusCode.판매_대기 && product.status !== ProductStatusCode.판매중) {
      toast.error("판매가 완료된 상품은 수정할 수 없습니다")
      return
    }

    const confirmMessage =
      "경고:\n판매중 상품의 정보를 수정하면\n수정 전 정보를 바탕으로 구매를 진행한 고객의\n컴플레인의 대상이 될 수 있습니다."
    if (!window.confirm(confirmMessage)) {
      return
    }

    if (product.saleType === SaleTypeCode.위탁판매) {
      const confirmMessageBeforeEditingEntrustedProductPrice =
        "경고:\n위탁판매 상품의 판매가 변경은 반드시 고객의 사전동의를 받아야하며\n동의없이 이루어진 판매가 변경으로 발생한 피해는\n온전히 변경한 사람 본인이 책임지는 것에 동의합니다."
      if (!window.confirm(confirmMessageBeforeEditingEntrustedProductPrice)) {
        setEditing(false)
        return
      }
    }

    try {
      await updateSellOrder(product?.sellOrder?.id, { price: parseInt(productPrice.toString()) }).then(() => {})
      setProduct((prevProduct) => ({ ...prevProduct, price: parseInt(productPrice.toString()) }))
      toast.success("판매가 변경 성공")
    } catch {
      toast.error("판매가 변경 실패")
    } finally {
      setEditing(false)
    }
  }

  // 상품화 완료 처리 (판매 노출 전 단계)
  const finishProductSetUp = () => {
    if (!productId) {
      return
    }

    if (checkRequired(product)) {
      setSaving(true)
      completeSetup(productId)
        .then((res) => {
          toast.success("상품화 완료")
          handleClose()
        })
        .catch((error) => {
          toast.error(`상품화 완료 실패 (${error.message || error})`)
        })
        .finally(() => setSaving(false))
    }
  }

  // 현재 상태에 대한 예상파매가 조회
  const getCurrentResellPrice = (product: PreProductType) => {
    const dto = {
      detailModel: { id: product?.detailModel?.id! },
      mainProductCondition: product.exposedProductInfo?.productCondition,
      accessoriesCondition: product.exposedProductInfo?.accessoriesCondition,
      officialGuarantee: product.exposedProductInfo?.officialGuarantee,
      stampingYear: product.exposedProductInfo?.stampingDate
        ? parseInt(product.exposedProductInfo?.stampingDate.substring(0, 4))
        : undefined,
      originalBoxCondition: product.exposedProductInfo?.originalBoxCondition,
    }
    getResellPrice(dto).then((res) => {
      setEstimatedPrice(res)
    })
  }

  const changeProductGuaranteeState = (
    key: "repurchaseGuarantee" | "repurchaseGuaranteePeriod" | "repurchaseGuaranteeRate",
    value: boolean | number | undefined
  ) => {
    setProduct((prevProduct) => ({ ...prevProduct, [key]: value }))
  }

  const toggleReSellGuaranteeOption = () => {
    const changedCheckBoxValue = !product.repurchaseGuarantee

    setProduct((prevProduct) => ({
      ...prevProduct,
      repurchaseGuarantee: changedCheckBoxValue,
      repurchaseGuaranteePeriod: !changedCheckBoxValue ? undefined : product.repurchaseGuaranteePeriod || 90,
      repurchaseGuaranteeRate: !changedCheckBoxValue ? undefined : product.repurchaseGuaranteeRate || 0.9,
    }))
  }

  useEffect(() => {
    if (!productId) {
      return
    }

    getProduct(productId).then((product) => {
      if (product?.exposedProductInfo?.stampingDate) {
        const initialDate = new Date(product.exposedProductInfo.stampingDate)
        setFormattedDate(formatDate(initialDate))
      } else {
        setFormattedDate("")
      }
      setProduct(product)
      setProductPrice(product?.price || 0)
      getCurrentResellPrice(product)
      getBrand(product?.brandSid || "ETC").then((brand) => {
        setBrand(brand)
      })
    })

    if (product?.sellOrder?.id) {
      getCancelSellOrderPenaltyFee({ sellOrder: { id: product?.sellOrder?.id } }).then((penalty) => {
        setPenalty(penalty.paymentAmount)
      })
    }
  }, [productId, product?.sellOrder?.id])

  return {
    brand,
    product,
    setProduct,
    toggleReSellGuaranteeOption,
    changeProductGuaranteeState,
    finishProductSetUp,
    changeProductPrice,
    updateOnDisplay,
    update,
    generateGeneralReview,
    saving,
    estimatedPrice,
    penalty,
    getCurrentResellPrice,
    editing,
    setEditing,
    setProductPrice,
    productPrice,
    formattedDate,
    setFormattedDate,
  }
}
