import styled from "@emotion/styled"
import { FormControl, MenuItem, Select } from "@mui/material"
import SearchBar from "components/Search/SearchBar"
import SearchInput from "components/Search/SearchBar/SearchInput"

const searchFilterMenuList: { value: "productId" | "site" | "name"; title: string }[] = [
  { value: "productId", title: "상품 ID" },
  { value: "site", title: "장소" },
  { value: "name", title: "지점" },
]

type Props = {
  searchParams: any
  changeSearchKeyword: (keyword: string) => void
  changeSearchFilterValue: (filterValue: "productId" | "site" | "name") => void
  onSearch: () => void
}

export const ProductTrackingLogSearchBar = ({
  searchParams,
  changeSearchKeyword,
  changeSearchFilterValue,
  onSearch,
}: Props) => {
  return (
    <Wrapper>
      <FormControl style={{ minWidth: "150px" }}>
        <Select<"productId" | "site" | "name">
          size={"small"}
          defaultValue={"productId"}
          required
          onChange={(e) => {
            const value = e.target.value as "productId" | "site" | "name"

            changeSearchFilterValue(value)
          }}
        >
          {searchFilterMenuList.map((menu) => (
            <MenuItem value={menu.value.toString()} key={menu.title}>
              {menu.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SearchBar>
        <SearchInput
          style={{ height: 40 }}
          value={searchParams.keyword}
          onChange={(e) => changeSearchKeyword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch()
            }
          }}
        />
      </SearchBar>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 80%;
  display: inline-flex;
  align-items: center;
  max-width: 800px;
`
