import React, { useEffect, useRef, useState } from "react"
import { SellOrderType } from "types/SellOrderType"
import { getSellOrdersForQR } from "../../../apis/sellOrderAPI"
import { Button, Dialog, Grid } from "@mui/material"
import { useReactToPrint } from "react-to-print"
import PolyBagQRTableToPrint from "./PloyBagQRTableToPrint"
import styled from "@emotion/styled"

type QRTableListToPrintProps = {
  open: boolean,
  orderIds: string[],
  handleClose: () => void,
}

const PolyBagQRTableListToPrint: React.FC<QRTableListToPrintProps> = ({ open, orderIds, handleClose }) => {

  const [sellOrders, setSellOrders] = useState<SellOrderType[]>()

  const handleClickPrint = useReactToPrint({
    // @ts-ignore
    content: () => ecuQRRef.current,
    copyStyles: true,
  })

  const ecuQRRef = useRef()

  useEffect(() => {
    if (orderIds) {
      orderIds &&
      getSellOrdersForQR(orderIds).then((res) => {
        setSellOrders(res)
      })
    }
  }, [orderIds])


  // @ts-ignore
  return (
    <Dialog open={open} maxWidth={"sm"}>
      <>
        <Grid container spacing={1}>
          <Grid alignItems={"space-between"} sx={{ marginBottom: 2 }}>
            <Button variant={"contained"} color={"error"} size={"small"} sx={{ margin: "10px 0 0 10px" }} onClick={handleClickPrint}>폴리백 QR 출력</Button>
            <Button variant={"contained"} color={"primary"} size={"small"} sx={{ margin: "10px 0 0 10px" }} onClick={() => handleClose()}>닫기</Button>
          </Grid>
          {/*@ts-ignore*/}
          <StyledPrintWrap ref={ecuQRRef}>
            {sellOrders &&
              sellOrders.map((s, index) => (<PolyBagQRTableToPrint sellOrder={s} key={index} />))
            }
          </StyledPrintWrap>
        </Grid>
      </>
    </Dialog>
  )
}

export default PolyBagQRTableListToPrint
const StyledPrintWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
	position: relative;
  @media print{
	  gap: 2mm;
  }
`