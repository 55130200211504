import { PaginationParamsType } from "types/PageType"
import { getCouponCodeList } from "apis/couponCodeAPI"
import { useCallback, useState } from "react"
import CouponCodeListTable from "./CouponCodeListTable"
import styled from "@emotion/styled"
import { useMount } from "react-use"
import CouponCodeListTableSearchBar from "pages/CouponCode/CouponCodeListPage/SearchBar"
import { CouponCodeListSearchParams, CouponCodeStatusCode, CouponCodeType } from "../../../types/CouponCodeType";
import Button from "../../../components/Button";
import EditCouponCodePopup from "./EditCouponCodePopup";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

export type CouponCodeListSearchType = {
  filter: keyof CouponCodeListSearchParams
  keyword: string | CouponCodeStatusCode
}

const LIST_FETCH_SIZE = 20

const CouponCodeListPage = () => {
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false)
  const [couponCodeList, setCouponCodeList] = useState<CouponCodeType[]>([])
  const [searchParams, setSearchParams] = useState<CouponCodeListSearchType>({
    filter: "code",
    keyword: "",
  })

  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: LIST_FETCH_SIZE,
  })

  const [selectedCouponCode, setSelectedCouponCode] = useState<CouponCodeType | undefined>()

  const parseSearchParams = useCallback((searchParams?: CouponCodeListSearchType) => {
    if (!searchParams) {
      return {}
    }
    return {
      [searchParams.filter]: searchParams.keyword,
    }
  }, [])

  const fetchCouponCodeList = useCallback(
    (pageNumber?: number, searchParams?: CouponCodeListSearchType) => {
      getCouponCodeList({ size: LIST_FETCH_SIZE, page: pageNumber, ...parseSearchParams(searchParams) }).then(
        ({ content, totalElements = 0, totalPages = 0, first, size = LIST_FETCH_SIZE, number = 0 }) => {
          setCouponCodeList(content || [])
          setPaginationParams((prev) => ({ ...prev, totalElements, totalPages, first, size, number }))
          if (!totalElements) toast.info("조회 결과가 없습니다.")
        }
      )
    },
    [parseSearchParams]
  )

  const changeSearchFilterValue = useCallback(
    (searchFilterValue: keyof CouponCodeListSearchParams) => {
      setSearchParams({ filter: searchFilterValue, keyword: "" })
      fetchCouponCodeList()
    },
    [fetchCouponCodeList]
  )

  const changeSearchKeyword = useCallback((keyword: string) => {
    setSearchParams((prev) => ({ ...prev, keyword }))
  }, [])

  const onSearch = useCallback(
    (keyword: CouponCodeListSearchType["keyword"]) => {
      fetchCouponCodeList(0, { ...searchParams, keyword })
      setPaginationParams((prev) => ({ ...prev, number: 0 }))
    },
    [fetchCouponCodeList, searchParams]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber - 1 }))
      fetchCouponCodeList(pageNumber - 1, searchParams)
    },
    [searchParams, fetchCouponCodeList]
  )
  const closePopupPage = () => {
    setIsEditPopupOpen(false)
    setSelectedCouponCode(undefined)
  }

    const onClickTableRow = (couponCode: CouponCodeType) => {
        setSelectedCouponCode(couponCode)
        setIsEditPopupOpen(true)
    }

    const [queryParam] = useSearchParams()
    useMount(() => {
      const keyword = queryParam.get("keyword") as string
      if(keyword) {
        setSearchParams({ ...searchParams, keyword })
        onSearch(keyword)
      } else
        fetchCouponCodeList()
  })

  return (
    <>
      <EditCouponCodePopup
        isOpen={isEditPopupOpen}
        closePopup={closePopupPage}
        selectedCouponCode={selectedCouponCode}
        fetchCouponCodeList={fetchCouponCodeList}
      />
      <PageHeaderWrapper>
        <ButtonWrapper>
          <Button onClick={() => setIsEditPopupOpen(true)}>쿠폰 코드 추가</Button>
        </ButtonWrapper>
        <CouponCodeListTableSearchBar
          searchParams={searchParams}
          changeSearchFilterValue={changeSearchFilterValue}
          changeSearchKeyword={changeSearchKeyword}
          onSearch={onSearch}
        />
      </PageHeaderWrapper>

      <CouponCodeListTable
        couponCodeList={couponCodeList}
        paginationParams={paginationParams}
        changePage={changePageNumber}
        onClickTableRow={onClickTableRow}
      />
    </>
  )
}

export default CouponCodeListPage

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 24px 0;
  margin-bottom: 20px;
`
const ButtonWrapper = styled.div``
