import PopupPage from "components/Popup/PopupPage"
import styled from "@emotion/styled"
import PopupPageTitle from "components/Popup/PopupPage/PopupPageTitle"
import PopupPageScrollContainer from "components/Popup/PopupPage/PopupPageScrollContainer"
import PopupPageFooter from "components/Popup/PopupPage/PopupPageFooter"
import Button from "components/Button"
import {useCallback, useEffect, useState} from "react"
import {UserBuyOrderType, UserDetailType, UserMemoTargetTypeCode, UserSellOrderType} from "types/UserType"
import {deleteUserMemo, getUserBuyOrderList, getUserDetail, getUserSellOrderList, postUserNickname,} from "apis/userAPI"

import {createUserMemo} from 'apis/userMemoAPI'
import {toast} from "react-toastify"
import {getAvailableUserPoint} from "apis/pointAPI"
import UserDetailContent from "./UserDetailContent"
import _ from "lodash"
import {useMount} from "react-use"
import {SellOrderStatusCode} from "types/SellOrderType"
import {BuyOrderStatusCode} from "types/BuyOrderType"
import {FormControlLabel, Switch} from "@mui/material";

type Props = {
  userId: string
  closePopup: () => void
}

export type UserOrderList<T> = {
  onGoingList: T[]
  finishedList: T[]
}

export const ON_GOING_SELL_ORDER_STATUS_LIST = [
  SellOrderStatusCode.픽업_준비,
  SellOrderStatusCode.입고중,
  SellOrderStatusCode.입고_완료,
  SellOrderStatusCode.진단중,
  SellOrderStatusCode.진단_완료,
  SellOrderStatusCode.판매_대기,
  SellOrderStatusCode.판매중,
  SellOrderStatusCode.결제_대기,
]

export const FINISHED_SELL_ORDER_STATUS_LIST = [
  SellOrderStatusCode.판매_완료,
  SellOrderStatusCode.페널티_대기,
  SellOrderStatusCode.판매_취소,
  SellOrderStatusCode.판매_포기,
  SellOrderStatusCode.판매_불합격,
  SellOrderStatusCode.반려,
]

export const ON_GOING_BUY_ORDER_STATUS_LIST = [
  BuyOrderStatusCode.결제_대기,
  BuyOrderStatusCode.상품_준비,
  BuyOrderStatusCode.배송_준비,
  BuyOrderStatusCode.진단중,
  BuyOrderStatusCode.배송중,
]

export const FINISHED_BUY_ORDER_STATUS_LIST = [
  BuyOrderStatusCode.반품_요청완료,
  BuyOrderStatusCode.반품_입고중,
  BuyOrderStatusCode.반품_입고완료,
  BuyOrderStatusCode.반품_완료,
  BuyOrderStatusCode.반품_불가,
  BuyOrderStatusCode.반송_완료,
  BuyOrderStatusCode.구매_실패,
  BuyOrderStatusCode.구매_취소,
  BuyOrderStatusCode.구매_완료,
  BuyOrderStatusCode.결제_만료,
  BuyOrderStatusCode.결제_실패,
]

const UserDetailPopupPage = ({ userId, closePopup }: Props) => {
  const [unmask, setUnmask] = useState(false)
  const [userPoint, setUserPoint] = useState(0)
  const [userDetail, setUserDetail] = useState<UserDetailType>({
    id: 0,
    status: "활성",
    authType: "이메일",
    email: "",
    hasViverPayDefaultCard: false,
    receiveInterestedItemPush: false,
    receiveOrderPush: false,
    receiveAdPush: false,
    termsAgreement: [],
    memos: [],
  })
  const [userBuyOrderList, setUserBuyOrderList] = useState<UserOrderList<UserBuyOrderType>>({
    onGoingList: [],
    finishedList: [],
  })

  const [userSellOrderList, setUserSellOrderList] = useState<UserOrderList<UserSellOrderType>>({
    onGoingList: [],
    finishedList: [],
  })

  useEffect(() => {
    getUserDetail(userId, unmask)
      .then((user) => setUserDetail(user))
      .catch((err) => {
        toast.error("회원 정보 조회에 실패했습니다.")
        console.log("Error", err)
      })
  }, [userId, unmask])

  const fetchUserPoint = useCallback(() => {
    getAvailableUserPoint(userId)
      .then(({ availablePoints }) => setUserPoint(availablePoints))
      .catch((e) => {
        // toast.error("포인트 조회에 실패했습니다.")
        setUserPoint(0)
      })
  }, [userId])

  const fetchUserSellOrder = useCallback(() => {
    getUserSellOrderList(userId)
      .then((content) => {
        setUserSellOrderList({
          onGoingList: _.filter(content, ({ sellOrderStatus }) =>
            ON_GOING_SELL_ORDER_STATUS_LIST.includes(sellOrderStatus)
          ),
          finishedList: _.filter(content, ({ sellOrderStatus }) =>
            FINISHED_SELL_ORDER_STATUS_LIST.includes(sellOrderStatus)
          ),
        })
      })
      .catch((e) => {
        toast.error("판매 이력 조회에 실패했습니다.")
      })
  }, [userId])

  const fetchUserBuyOrder = useCallback(() => {
    getUserBuyOrderList(userId)
      .then((content) =>
        setUserBuyOrderList({
          onGoingList: _.filter(content, ({ buyOrderStatus }) =>
            ON_GOING_BUY_ORDER_STATUS_LIST.includes(buyOrderStatus)
          ),
          finishedList: _.filter(content, ({ buyOrderStatus }) =>
            FINISHED_BUY_ORDER_STATUS_LIST.includes(buyOrderStatus)
          ),
        })
      )
      .catch((e) => {
        toast.error("구매 이력 조회에 실패했습니다.")
      })
  }, [userId])

  const changeNickname = useCallback(
    (nickname: string) => {
      postUserNickname(userId, nickname)
        .then(() => {
          setUserDetail((prev) => ({
            ...prev,
            nickname,
          }))
        })
        .catch(() => toast.error("닉네임 변경에 실패했습니다."))
    },
    [userId]
  )

  const changePhoneNumber = useCallback(
    (phoneNumber: string) => {
      postUserNickname(userId, phoneNumber)
        .then(() => {
          setUserDetail((prev) => ({
            ...prev,
            phone: phoneNumber,
          }))
        })
        .catch(() => toast.error("전화번호 변경에 실패했습니다."))
    },
    [userId]
  )

  const saveMemo = useCallback(
    (memo: string) => {
      createUserMemo({userId: parseInt(userId), memo: memo, targetType: UserMemoTargetTypeCode.USER})
        .then((memo) => {
          setUserDetail((prev) => ({
            ...prev,
            memos: [...prev.memos, memo],
          }))
        })
        .catch(() => toast.error("메모 저장에 실패했습니다."))
    },
    [userId]
  )

  const deleteMemo = useCallback(
    (memoId: number) => {
      deleteUserMemo(userId, memoId)
        .then(() => {
          setUserDetail((prev) => ({
            ...prev,
            memos: prev.memos.filter((prevMemo) => prevMemo.id !== memoId),
          }))
        })
        .catch(() => toast.error("메모 삭제에 실패했습니다."))
    },
    [userId]
  )

  useMount(() => {
    Promise.all([fetchUserPoint(), fetchUserSellOrder(), fetchUserBuyOrder()])
  })

  if (!userDetail) {
    return <></>
  }

  return (
    <PopupPage isOpen={!!userId}>
      <Wrapper>
        <PopupPageTitle>회원 정보</PopupPageTitle>

        <FormControlLabel
            style={{justifyContent:"right", color: "#ed3030"}}
            control={<Switch color="warning" value={unmask}/>}
            label="개인정보 마스킹 해제"
            checked={unmask}
            onChange={(event, checked) => {
              if (checked) {
                checked = window.confirm("업무상 고객의 개인정보를 조회합니다." +
                    "\n\n* 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 경우 " +
                    "\n5년 이하의 징역 또는 5천만원 이하의 벌금에 처해질 수 있습니다.")
              }
              setUnmask(checked)
            }}
        />

        <PopupPageScrollContainer>
          <UserDetailContent
            userPoint={userPoint}
            user={userDetail}
            changeNickname={changeNickname}
            changePhoneNumber={changePhoneNumber}
            saveMemo={saveMemo}
            deleteMemo={deleteMemo}
            userBuyOrderList={userBuyOrderList}
            userSellOrderList={userSellOrderList}
          />
        </PopupPageScrollContainer>
        <PopupPageFooter>
          <Button buttonStyle="black-border" onClick={closePopup}>
            닫기
          </Button>
        </PopupPageFooter>
      </Wrapper>
    </PopupPage>
  )
}

export default UserDetailPopupPage

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
`
