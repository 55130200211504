import {FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField} from "@mui/material"
import React, {useEffect, useReducer} from "react"
import ProductTable from "./ProductTable"
import {PageType} from "types/PageType"
import {getSellOrders} from "apis/sellOrderAPI"
import {OrderType, SellOrderListSearchParams, SellOrderStatusCode, SellOrderType} from "types/SellOrderType"
import {useSearchParams} from "react-router-dom"
import {DateTime} from "luxon"
import {CountType} from "types/CountType"
import Counters from "../SellOrder/Counters"
import {DesktopDatePicker} from "@mui/lab"
import {countProducts} from "apis/productAPI"
import {toApplyCounts} from "utils/SellOrderTypeConverter"
import EditPreProductPopup from "../PreProduct/EditPreProductPopup"
import BeforeDiagnosisProductPopup from "./BeforeDiagnosisProductPopup";

export interface ProductState {
  selectedIds: string[]
  sellOrders: PageType<SellOrderType>
  productCount: CountType[]
  isShowEditPopup: boolean
  editSellOrder?: SellOrderType
  startDateFilter?: DateTime
  endDateFilter?: DateTime
  searchFilterKey?: keyof SellOrderListSearchParams
  searchFilterValue?: string | null
  refresh: boolean
  loading: boolean
}

const initState: (productId?: string | null) => ProductState = (productId) => {
  let state: ProductState = {
    selectedIds: [],
    sellOrders: {
      content: [],
      pageable: { pageSize: 25, pageNumber: 0 },
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
    },
    productCount: [],
    isShowEditPopup: false,
    editSellOrder: undefined,
    startDateFilter: undefined,
    endDateFilter: undefined,
    searchFilterKey: "productId",
    searchFilterValue: undefined,
    refresh: false,
    loading: false,
  }

  if (productId) {
    state.searchFilterKey = "productId"
    state.searchFilterValue = productId
  }

  return state
}

interface ProductAction {
  type: string
  payload?: any
}

function reducer(state: ProductState, action: ProductAction): ProductState {
  switch (action.type) {
    case "SELECTION_PRODUCT_IDS":
      return { ...state, selectedIds: action.payload }
    case "FETCH_SELLORDER":
      return { ...state, sellOrders: action.payload }
    case "FETCH_PRODUCT_COUNT":
      return { ...state, productCount: action.payload }
    case "CHANGE_START_DATE":
      return { ...state, startDateFilter: action.payload }
    case "CHANGE_END_DATE":
      return { ...state, endDateFilter: action.payload }
    case "SHOW_EDIT_PRODUCT_POPUP":
      return { ...state, isShowEditPopup: true, editSellOrder: action.payload }
    case "HIDE_EDIT_PRODUCT_POPUP":
      return { ...state, isShowEditPopup: false, editSellOrder: undefined }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: null }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
  }

  return state
}

const Products = () => {
  const [currentSearchParams] = useSearchParams()
  const [state, dispatch] = useReducer(reducer, initState(currentSearchParams.get("productId")))

  const fetchSellOrders = (searchParams: SellOrderListSearchParams = {}) => {
    let newParams: SellOrderListSearchParams = {
      ...searchParams,
      sellOrderType: [OrderType.위탁, OrderType.진단전, OrderType.직매입],
      status: (currentSearchParams.get("status")?.split(",") as SellOrderStatusCode[]) || [
        SellOrderStatusCode.판매중,
        SellOrderStatusCode.결제_대기,
        SellOrderStatusCode.판매_완료,
        SellOrderStatusCode.페널티_대기,
        SellOrderStatusCode.판매_포기,
      ],
      sellerId: currentSearchParams.get("sellerId") ?? undefined,
      sellerName: currentSearchParams.get("sellerName") ?? undefined,
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    dispatch({ type: "START_LOADING" })
    getSellOrders(newParams)
      .then((sellOrders) => dispatch({ type: "FETCH_SELLORDER", payload: sellOrders }))
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  const fetchSellOrderCounts = (searchParams: SellOrderListSearchParams = {}) => {
    countProducts({
      ...searchParams,
      sellOrderType: [OrderType.위탁, OrderType.진단전, OrderType.직매입],
      sellOrderStatus: [
        SellOrderStatusCode.판매중,
        SellOrderStatusCode.결제_대기,
        SellOrderStatusCode.판매_완료,
        SellOrderStatusCode.페널티_대기,
        SellOrderStatusCode.판매_포기,
      ],
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
    }).then((result) => {
      dispatch({
        type: "FETCH_PRODUCT_COUNT",
        payload: toApplyCounts(
          result,
          [SellOrderStatusCode.판매중, SellOrderStatusCode.결제_대기, SellOrderStatusCode.판매_완료],
          ".",
          [],
          true
        ),
      })
    })
  }

  useEffect(() => {
    fetchSellOrders({
      page: state.sellOrders.pageable?.pageNumber || 0,
      size: state.sellOrders.pageable?.pageSize || 25,
    })
    fetchSellOrderCounts()
  }, [state.refresh, currentSearchParams])

  return (
    <Grid container spacing={2}>

      {
        state.editSellOrder?.sellOrderType == OrderType.진단전
          ? <BeforeDiagnosisProductPopup
              open={state.isShowEditPopup}
              selectedProductId={state.editSellOrder?.product?.id}
              handleClose={() => {
                dispatch({ type: "HIDE_EDIT_PRODUCT_POPUP" })
                dispatch({ type: "REFRESH" })
              }}
          />
          : <EditPreProductPopup
              open={state.isShowEditPopup}
              selectedSellOrderId={state.editSellOrder?.id}
              selectedProductId={state.editSellOrder?.product?.id}
              handleClose={() => {
                dispatch({ type: "HIDE_EDIT_PRODUCT_POPUP" })
                dispatch({ type: "REFRESH" })
              }}
          />
      }
      {
        <Grid item xs={12}>
          <Counters params={state.productCount} selectedLabel={currentSearchParams.get("status")} />
        </Grid>
      }
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}>
          <Grid item xs={12} container spacing={1}>
            <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
                <InputLabel style={{ lineHeight: "45px", marginRight: "10px" }}>판매 신청일</InputLabel>
                <DesktopDatePicker
                  label="시작일"
                  inputFormat="yyyy-MM-dd"
                  value={state.startDateFilter ?? null}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_START_DATE", payload: params })
                    dispatch({ type: "REFRESH" })
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                />
                <DesktopDatePicker
                  label="종료일"
                  inputFormat="yyyy-MM-dd"
                  value={state.endDateFilter  ?? null}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_END_DATE", payload: params })
                    dispatch({ type: "REFRESH" })
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                />
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" spacing={0}>
              <FormControl sx={{ width: "160px" }} size={"small"}>
                <InputLabel id="search_key">검색항목</InputLabel>
                <Select
                  value={state.searchFilterKey || ""}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                    dispatch({ type: "REFRESH" })
                  }}
                >
                  <MenuItem value={"id"}>판매주문번호</MenuItem>
                  <MenuItem value={"productId"}>상품번호</MenuItem>
                  <MenuItem value={"productTitle"}>상품명</MenuItem>
                  <MenuItem value={"sellerName"}>판매자 이름</MenuItem>
                  <MenuItem value={"sellerPhone"}>판매자 연락처</MenuItem>
                  <MenuItem value={"productSaleType"}>판매방식</MenuItem>
                </Select>
              </FormControl>
              {state.searchFilterKey === "productSaleType" ? (
                <FormControl sx={{ width: "220px" }} size={"small"}>
                  <Select
                    value={state.searchFilterValue || "전체"}
                    onChange={(params) => {
                      dispatch({
                        type: "CHANGE_SEARCH_FILTER_VALUE",
                        payload: params.target.value === "전체" ? null : params.target.value,
                      })
                      dispatch({ type: "REFRESH" })
                    }}
                  >
                    <MenuItem value={"전체"}>전체</MenuItem>
                    <MenuItem value={"전시용"}>전시용</MenuItem>
                    <MenuItem value={"직접판매"}>직접판매</MenuItem>
                    <MenuItem value={"위탁판매"}>위탁판매</MenuItem>
                    <MenuItem value={"매입보증판매"}>매입보증판매</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  sx={{ width: "220px" }}
                  size={"small"}
                  value={state.searchFilterValue || ""}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: params.target.value })
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") dispatch({ type: "REFRESH" })
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <ProductTable state={state} dispatch={dispatch} fetch={fetchSellOrders} components={[]} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Products
