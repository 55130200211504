import styled from "@emotion/styled"
import { UserOrderList } from "pages/User/UserDetailPopupPage"
import { UserBuyOrderType, UserDetailType, UserSellOrderType } from "types/UserType"
import UserInformationTable from "./UserInformationTable"
import UserTransactionTable from "./UserTransactionTable"
import React from "react"
import UserSuperLoginHistoryTable from "./UserSuperLoginHistoryTable"

type Props = {
  user?: UserDetailType
  userPoint: number
  changeNickname: (nickname: string) => void
  changePhoneNumber: (phoneNumber: string) => void
  saveMemo: (memo: string) => void
  deleteMemo: (memoId: number) => void
  userBuyOrderList: UserOrderList<UserBuyOrderType>
  userSellOrderList: UserOrderList<UserSellOrderType>
}

const UserDetailContent = ({
  user,
  userPoint,
  changeNickname,
  changePhoneNumber,
  saveMemo,
  deleteMemo,
  userBuyOrderList,
  userSellOrderList,
}: Props) => {
  if (!user) {
    return <></>
  }

  return (
    <Wrapper>
      <UserInformationTable
        user={user}
        userPoint={userPoint}
        changeNickname={changeNickname}
        changePhoneNumber={changePhoneNumber}
        saveMemo={saveMemo}
        deleteMemo={deleteMemo}
      />
      <Spacer />
      <UserTransactionTable
        userSellOrderList={userSellOrderList}
        userBuyOrderList={userBuyOrderList}
        userId={user.id}
      />
      <Spacer />
      <UserSuperLoginHistoryTable
        userId={user.id}
      />
    </Wrapper>
  )
}

export default UserDetailContent

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 1024px;

  padding-bottom: 20px;
`
const Spacer = styled.div`
  height: 40px;
`
