import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Button, MenuItem, Select, TextField } from "@mui/material"
import { updateShippingMethod, updateTrackingNo } from "../../../../apis/shippingAPI"
import { toast } from "react-toastify"
import { SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import * as XLSX from "xlsx"
import { DateTime } from "luxon"
import { ShippingMethod } from "../../../../types/ShippingMethod"
import ChangeShippingAddress from "../../../../components/ChangeShippingAddress/ChangeShippingAddress"
import { BuyOrderStatusCode } from "../../../../types/BuyOrderType"
import { Sell } from "@mui/icons-material"

type Props = {
  sellOrder?: SellOrderType
  setSellOrder: Dispatch<SetStateAction<SellOrderType | undefined>>
  handleClose: Function
}

const IncomingShippingInfo = ({ sellOrder, setSellOrder, handleClose }: Props) => {
  const [trackingNo, setTrackingNo] = useState<string>()
  const [shippingMethod, setShippingMethod] = useState<ShippingMethod>()
  const [shippingAddress, setShippingAddress] = useState<String>(`${sellOrder?.shipping?.address || ""} ${sellOrder?.shipping?.addressDetail || ""}`)

  useEffect(() => {
    setShippingMethod(sellOrder?.shipping?.shippingMethod)
    setShippingAddress(`${sellOrder?.shipping?.address || ""} ${sellOrder?.shipping?.addressDetail || ""}`)
  }, [sellOrder?.shipping?.shippingMethod])

  const updateShipping = (event: any) => {
    if (sellOrder?.shipping?.id && trackingNo) {
      updateTrackingNo(sellOrder?.shipping.id, { trackingNo: trackingNo }).catch((error) => {
        toast.error(`운송장번호 갱신 실패 (${error.message || error})`)
      })
    } else {
      toast.error(`배송정보를 찾을 수 없습니다. 관리자에게 문의하세요.`)
    }
  }

  const downloadExcel = () => {
    if (!sellOrder?.shipping) {
      toast.error("배송정보를 찾을 수 없습니다")
      return
    }

    const it = sellOrder?.shipping!
    const data = [
      {
        "배송형태(배송 OR 반품)": it.shippingType == "발송" ? "배송" : "반품",
        고객명: it.name,
        우편번호: it.zipCode,
        지역: it.address?.split(" ")?.[0],
        주소: it.address,
        상세주소: it.addressDetail,
        "고객 핸드폰 번호": it.phone,
        ITEM: sellOrder?.product?.title,
        WEIGHT: "0.1",
        VALUE: sellOrder?.productPrice,
        MEMO: "",
        "고객사 ORDER NUMBER": sellOrder?.shipping?.id,
      },
    ]
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" })
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" })
    XLSX.writeFile(workbook, `배송_템플릿_${DateTime.now().toFormat("yyyyMMdd_HHmmss")}.xlsx`)
  }

  const handleSetAddress = (address: string) => {
    setShippingAddress(address)
  }

  const handleShippingMethod = () => {
    const shippingId = sellOrder?.shipping?.id

    if (window.confirm(`판매주문번호 [${sellOrder?.shipping?.id}]의 입고 방식을 ${shippingMethod}로 변경하시겠습니까?`)) {
      if (typeof shippingId !== "undefined" && typeof shippingMethod !== "undefined") {
        updateShippingMethod(shippingId, shippingMethod).then((res) => {
          setSellOrder((sellOrder) => ({
            ...sellOrder,
            shipping: res,
          }))
        }).catch((ex) => {
          toast.error(ex.message)
        })
      }
    }
  }

  return (
    <>
      <h3>입고 배송정보</h3>
      <table className={"basic"}>
        <tbody>
        <tr>
          <td className={"header"} colSpan={2}>
            운송장 번호
          </td>
          <td className={"header"}>배송상태</td>
          <td className={"header"}>배송타입</td>
          <td className={"header"} colSpan={2}>입고방식</td>
          <td className={"header"}>이름</td>
          <td className={"header"}>연락처</td>
          <td className={"header"} colSpan={2}>
            발송지 주소
            <ChangeShippingAddress
              disabled={sellOrder?.sellOrderStatus === undefined || !([SellOrderStatusCode.판매_대기, SellOrderStatusCode.판매중, SellOrderStatusCode.결제_대기, SellOrderStatusCode.픽업_준비].includes(sellOrder.sellOrderStatus))}
              shippingId={sellOrder?.shipping?.id}
              onUpdateAddress={handleSetAddress}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <TextField
              placeholder={"운송장번호"}
              size={"small"}
              value={trackingNo ?? sellOrder?.shipping?.trackingNo}
              onChange={(event) => setTrackingNo(event.target.value)}
            />
            <Button variant={"outlined"} onClick={updateShipping}>
              {(trackingNo ?? sellOrder?.shipping?.trackingNo) ? "변경" : "입력"}
            </Button>
          </td>
          <td>{sellOrder?.shipping?.shippingStatus}</td>
          <td>{sellOrder?.shipping?.shippingType}</td>
          <td colSpan={2}>
            <Select<ShippingMethod>
              size={"small"}
              value={shippingMethod || ""}
              onChange={(params) => {
                setShippingMethod(params.target.value as ShippingMethod)
              }}
            >
              <MenuItem value={"안전배송"}>발렉스</MenuItem>
              <MenuItem value={"안전배송_2"}>일양택배</MenuItem>
              <MenuItem value={"쇼룸방문"}>쇼룸방문</MenuItem>
              <MenuItem value={"일반택배"}>일반택배</MenuItem>
            </Select>
            <Button variant={"outlined"} onClick={handleShippingMethod} disabled={sellOrder?.shipping?.shippingMethod === shippingMethod}>
              수정
            </Button>
          </td>
          <td>{sellOrder?.shipping?.name}</td>
          <td>{sellOrder?.shipping?.phone}</td>
          <td colSpan={2}>
            {shippingAddress}
          </td>
        </tr>
        <tr>
          <td className={"header"} colSpan={10}>
            배송 추적
          </td>
        </tr>
        {sellOrder?.shipping?.journey?.length ? (
          sellOrder?.shipping?.journey?.map((it) => (
            <tr>
              <td colSpan={4}>{it.when}</td>
              <td colSpan={4}>{it.how}</td>
              <td colSpan={3}>{it.where}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={10}>배송정보가 없습니다</td>
          </tr>
        )}
        </tbody>
      </table>
    </>
  )
}

export default IncomingShippingInfo
