import {httpGet, httpGetToBlob, httpPost, httpPostMultipartFile, httpPut} from "../utils/httpAPI"
import {
  CreateSellOrderRequestBodyType,
  SellOrderBatchUploadResultType,
  SellOrderListSearchParams,
  SellOrderType,
  SellOrderExcelParams,
} from "types/SellOrderType"
import { CountType } from "types/CountType"
import { PageType } from "types/PageType"
import { CodeDetailType } from "../types/CodeDetailType"

export const getCodeDetailByCode: (code: any) => Promise<CodeDetailType[]> = (code) =>
  httpGet(`/code-detail/${code}`)
