export enum CouponCodeStatusCode {
  활성 = "활성",
  취소 = "취소",
}

export enum CouponTypeCode {
  포인트지급 = "포인트지급",
}

export enum CouponAvailableDateTypeCode {
  만료일_직접_지정 = "만료일_직접_지정",
  쿠폰_사용일_기준 = "쿠폰_사용일_기준",
}

export interface CouponCodeType {
  code: string,
  status: CouponCodeStatusCode,
  name: string,
  type: CouponTypeCode,
  usageCount: number,
  maxUsageCount: number,
  points: number,
  availableDateType: CouponAvailableDateTypeCode,
  availableDays?: number,
  availableDate?: string,
  startedAt: string,
  endedAt: string,
  createdAt: string,
  createdBy: number,
  updatedAt: string,
  updatedBy: number,
}


export type CouponCodeBodyType = {
  code?: string,
  name?: string,
  status?: CouponCodeStatusCode,
  type?: CouponTypeCode,
  maxUsageCount?: number,
  points?: number,
  availableDateType: CouponAvailableDateTypeCode,
  availableDays?: number,
  availableDate?: string,
  startedAt?: string,
  endedAt?: string,
}

export type CouponCodeListSearchParams = {
  size?: number,
  page?: number,
  code?: string,
  name?: string,
  once?: boolean,
  startDate?: string,
  endDate?: string,
}
