import React, { useState } from "react"
import Modal from "components/Modal"
import styled from "@emotion/styled"
import { Button, Typography } from "@mui/material"
import { uploadSellOrderBatchByExcel } from "apis/sellOrderAPI"
import { downloadExcel } from "utils/excelUtil"
import { SellOrderOriginCellValue } from "types/SellOrderType"
import { Spacer } from "components/Spacer"
import Callout from "components/Callout"

type Props = {
  isOpen: boolean
  onCloseModal: () => void
}

const SellOrderExcelFileUploaderModal = ({ isOpen, onCloseModal }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState<FileList | undefined>()

  const handleSubmitExcel = () => {
    if (!selectedFile) {
      alert("파일을 선택해주세요.")
      return
    }

    setIsLoading(true)

    uploadSellOrderBatchByExcel(selectedFile)
      .then((res) => {
          handleDownloadExcel(res.dataList)
          if (res.failureCount === 0) {
            alert(`총 ${res.succeedCount}건 등록 되었습니다.`)
            handleModalClose()
          } else {
            alert(`총 ${res.totalCount}건 오류 ${res.failureCount}건이 발생했습니다.\n오류 내용을 결과 파일로 확인 후 수정해주세요.`)
          }
        },
      )
      .catch((e) => alert(`파일 업로드에 실패했습니다`),
      ).finally(() =>
      setIsLoading(false),
    )
  }

  const handleDownloadExcel = (dataList: SellOrderOriginCellValue[]) => {
    const excelData = dataList.map((data) => {
      return {
        "처리 결과": data.status,
        "오류 내용": data.error,
        "판매자ID": data.userId,
        "판매방식": data.sellOrderType,
        "배송방식": data.shippingMethod,
        "브랜드명": data.brandSid,
        "모델명": data.modelName,
        "상세모델명": data.detailModelName,
        "레퍼런스": data.reference,
        "풀레퍼런스": data.detailModel,
        "상품명": data.productTitle,
        "판매가": data.price,
        "제품상태": data.mainProductCondition,
        "보증서유무": data.officialGuarantee,
        "정품박스유무": data.originalBoxCondition,
        "부속품": data.accessoriesCondition,
        "스탬핑연도": data.stampingYear,
        "스탬핑월": data.stampingMonth,
        "전시판매 동의": data.exhibitionSale,
        "찍힘/눌림": data.stab,
        "글라스 흠집": data.glassScratch,
        "링크": data.braceletLinks,
        "보호필름": data.protectingFilm,
        "대표이미지": data.repImage,
        "상세이미지1": data.detailImage1,
        "상세이미지2": data.detailImage2,
        "상세이미지3": data.detailImage3,
        "상세이미지4": data.detailImage4,
        "상세이미지5": data.detailImage5,
        "상품설명": data.userDescription,
      }
    })
    downloadExcel(excelData, "판매주문_대량등록_결과")
  }

  const handleModalClose = () => {
    setSelectedFile(undefined)
    onCloseModal()
  }

  return <Modal isOpen={isOpen}>
    <ModalContentWrapper>
      <ModalContentHeader>
        <h3>판매주문 대량등록</h3>
        <a href={`${process.env.PUBLIC_URL}/excel/판매주문_대량등록_양식.xlsx`} download target="_blank">
          <Button
            variant={"outlined"}
            size={"small"}
            color={"error"}
            style={{ margin: "0 2px" }}>
            엑셀 양식 다운로드
          </Button>
        </a>
      </ModalContentHeader>
      <ModalContentBody>
        <FileUploader
          type={"file"}
          accept=".xlsx"
          onChange={(e) => {
            if (e.target.files) {
              setSelectedFile(e.target.files)
            }
          }}
        />

        <Spacer space={10} />

        <div style={{ marginLeft: "auto" }}>
          <Button variant={"contained"} size={"small"} disabled={isLoading} onClick={handleSubmitExcel}>
            업로드
          </Button>
        </div>

        <Callout type={"warning"} title={"주의 사항"}>
          <div style={{ fontSize: "14px" }}>* 데이터 검증을 모두 통과해야만 대량 등록이 가능합니다.</div>
          <div style={{ fontSize: "14px" }}>* 업로드 버튼을 누른 뒤 검증 결과를 엑셀로 확인해주세요.</div>
          <div style={{ fontSize: "14px" }}>* 동일한 상품의 판매주문이 여러번 등록되지 않도록 주의해주세요!</div>
        </Callout>
      </ModalContentBody>
      <ModalContentFooter>
        <Button variant={"outlined"} size={"small"} onClick={handleModalClose}>
          닫기
        </Button>
      </ModalContentFooter>
    </ModalContentWrapper>
  </Modal>
}

export default SellOrderExcelFileUploaderModal

const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 500px;
`

const ModalContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const ModalContentBody = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
`

const ModalContentFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

const FileUploader = styled.input`
    flex: 1;
    height: 40px;
    padding: 5px 10px;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px;
`
