import { Switch } from "@mui/material"
import { FunctionComponent, useMemo } from "react"
import { SpecLabelType } from "./useDetailModelCreatePopupStore"

type SpecLabelTextType =
  | "다이얼 색상"
  | "다이얼 특성"
  | "브레이슬릿 종류"
  | "글라스"
  | "케이스 직경"
  | "케이스 크기"
  | "케이스 두께"
  | "무게"
  | "방수"
  | "베젤 소재"
  | "베젤 종류"
  | "무브먼트"
  | "칼리버"
  | "진동수"
  | "파워리저브"
  | "MANUFACTURED IN"
  | "크라운 소재"
  | "클라스프"
  | "다이얼"
  | "핸즈"

type SpecLabelMaterialType = "케이스 소재" | "브레이슬릿 소재"
type SpecOptionType =
  | { type: "text"; label: SpecLabelTextType; title: string; placeholder?: string }
  | { type: "material"; label: SpecLabelMaterialType; title: string }
const specOptions: SpecOptionType[] = [
  { type: "text", label: "다이얼 색상", title: "다이얼 색상", placeholder: "ex) 블랙" },
  { type: "text", label: "다이얼 특성", title: "다이얼 특성", placeholder: "ex) 메테오라이트" },
  { type: "material", label: "케이스 소재", title: "케이스 소재" },
  { type: "material", label: "브레이슬릿 소재", title: "브레이슬릿 소재" },
  { type: "text", label: "브레이슬릿 종류", title: "브레이슬릿 종류", placeholder: "ex) 오이스터 브레이슬릿" },
  { type: "text", label: "글라스", title: "글라스", placeholder: "ex) 사파이어 글라스" },
  { type: "text", label: "케이스 직경", title: "케이스 직경", placeholder: "ex) 40mm" },
  { type: "text", label: "케이스 크기", title: "케이스 크기", placeholder: "ex) 35.5mm x 25.5mm" },
  { type: "text", label: "케이스 두께", title: "케이스 두께", placeholder: "ex) 12.5mm" },
  { type: "text", label: "무게", title: "무게", placeholder: "ex) 180g" },
  { type: "text", label: "방수", title: "방수", placeholder: "ex) 500m" },
  { type: "text", label: "베젤 소재", title: "베젤 소재", placeholder: "ex) 세라믹" },
  { type: "text", label: "베젤 종류", title: "베젤 종류", placeholder: "ex) 타키미터" },
  { type: "text", label: "무브먼트", title: "무브먼트", placeholder: "ex) 셀프와인딩" },
  { type: "text", label: "칼리버", title: "칼리버", placeholder: "ex) Cal.4130" },
  { type: "text", label: "진동수", title: "진동수", placeholder: "ex) 28,800 진동" },
  { type: "text", label: "파워리저브", title: "파워리저브", placeholder: "ex) 약 72시간" },
  { type: "text", label: "MANUFACTURED IN", title: "제조국", placeholder: "ex) Switzerland" },
  { type: "text", label: "크라운 소재", title: "크라운 소재", placeholder: "ex) Stainless steel" },
  { type: "text", label: "클라스프", title: "클라스프", placeholder: "ex) Folding clasp" },
  { type: "text", label: "다이얼", title: "다이얼", placeholder: "ex) Applied hour markers" },
  { type: "text", label: "핸즈", title: "핸즈", placeholder: "ex) Hour + minute + seconds" },
]

export const DetailModelSpecInput: FunctionComponent<{
  specs: { [Label in SpecLabelType]: { label: Label; value: string; allowDisplay: boolean; allowSearch: boolean } }
  onChangeSpecs: (specs: {
    [Label in SpecLabelType]: { label: Label; value: string; allowDisplay: boolean; allowSearch: boolean }
  }) => void
}> = ({ specs, onChangeSpecs }) => {
  return (
    <div>
      <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", height: 38 }}>
        모델 상세 정보
      </div>
      <div style={{ height: 16 }} />
      {specOptions.map((specOption, index) => {
        if (specOption.type === "text") {
          return (
            <DetailModelSpecTextField
              key={`${index}-${specOption.label}`}
              label={specOption.title}
              placeholder={specOption.placeholder}
              spec={specs[specOption.label]}
              onChangeSpec={(nextSpec) => {
                onChangeSpecs({ ...specs, [specOption.label]: nextSpec })
              }}
            />
          )
        }

        if (specOption.type === "material") {
          return (
            <DetailModelSpecMaterialField
              key={`${index}-${specOption.label}`}
              label={specOption.title}
              spec={specs[specOption.label]}
              onChangeSpec={(nextSpec) => {
                onChangeSpecs({ ...specs, [specOption.label]: nextSpec })
              }}
            />
          )
        }
        return null
      })}
    </div>
  )
}

const DetailModelSpecTextField: FunctionComponent<{
  label: string
  placeholder?: string
  spec: { label: string; value: string; allowDisplay: boolean; allowSearch: boolean }
  onChangeSpec: (spec: { label: string; value: string; allowDisplay: boolean; allowSearch: boolean }) => void
}> = ({ label, placeholder, spec, onChangeSpec }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        padding: "8px 0",
        borderBottom: "1px solid #F0F2F5",
      }}
    >
      <div style={{ width: 120 }}>{label}</div>
      <div style={{ width: 8 }} />
      <input
        style={{
          flex: 1,
          height: 38,
          border: "none",
          outline: "none",
          fontSize: 14,
          fontFamily: "Pretendard",
          lineHeight: "18px",
        }}
        type="text"
        placeholder={placeholder}
        value={spec.value}
        onChange={(event) => onChangeSpec({ ...spec, value: event.target.value })}
      />
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          width: 112,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        검색
        <Switch checked={spec.allowSearch} onChange={(_, checked) => onChangeSpec({ ...spec, allowSearch: checked })} />
      </div>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          width: 112,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        노출
        <Switch
          checked={spec.allowDisplay}
          onChange={(_, checked) => onChangeSpec({ ...spec, allowDisplay: checked })}
        />
      </div>
    </div>
  )
}

type Material =
  | "스테인리스 스틸"
  | "옐로우 골드"
  | "화이트 골드"
  | "플래티넘"
  | "로즈 골드"
  | "티타늄"
  | "세라믹"
  | "브론즈"
  | "실버"
  | "사파이어"
  | "카본 섬유"

const materialOptions: Material[] = [
  "스테인리스 스틸",
  "옐로우 골드",
  "화이트 골드",
  "플래티넘",
  "로즈 골드",
  "티타늄",
  "세라믹",
  "브론즈",
  "실버",
  "사파이어",
  "카본 섬유",
]
const DetailModelSpecMaterialField: FunctionComponent<{
  label: string
  spec: { label: string; value: string; allowDisplay: boolean; allowSearch: boolean }
  onChangeSpec: (spec: { label: string; value: string; allowDisplay: boolean; allowSearch: boolean }) => void
}> = ({ label, spec, onChangeSpec }) => {
  const specValueMaterials = useMemo(
    () =>
      spec.value
        ? spec.value
            .split(/[,+]/)
            .map((valueMaterial) => valueMaterial.trim())
            .filter((valueMaterial) => valueMaterial)
        : [],
    [spec.value]
  )

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        padding: "8px 0",
        borderBottom: "1px solid #F0F2F5",
      }}
    >
      <div style={{ width: 120 }}>{label}</div>
      <div style={{ width: 8 }} />
      <div style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        {materialOptions.map((materialOption, index) => (
          <label
            key={`${index}-${materialOption}`}
            style={{ display: "inline-flex", flexDirection: "row", alignItems: "start", cursor: "pointer" }}
          >
            <input
              type="checkbox"
              checked={specValueMaterials.includes(materialOption)}
              onChange={(event) => {
                if (event.target.checked) {
                  onChangeSpec({
                    ...spec,
                    value: [...specValueMaterials, materialOption].join(", "),
                  })
                } else {
                  onChangeSpec({
                    ...spec,
                    value: specValueMaterials
                      .filter((specValueMaterial) => specValueMaterial !== materialOption)
                      .join(", "),
                  })
                }
              }}
            />
            <div style={{ width: 5 }} />
            {materialOption}
            <div style={{ width: 10 }} />
          </label>
        ))}
        {label === "케이스 소재" && (
          <input
            type="text"
            style={{ height: 26 }}
            maxLength={20}
            placeholder="직접 입력"
            value={spec.value.split(", ").find((v) => !materialOptions.includes(v as Material)) || ""}
            onChange={(e) => {
              // 직접 입력은 한 개만 받을 수 있도록 콤마 제거
              const newValue = e.target.value.replace(/,/g, "")
              onChangeSpec({
                ...spec,
                value: [
                  ...specValueMaterials.filter((v) => materialOptions.includes(v as Material)),
                  ...(newValue ? [newValue] : []),
                ].join(", "),
              })
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          width: 112,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        검색
        <Switch checked={spec.allowSearch} onChange={(_, checked) => onChangeSpec({ ...spec, allowSearch: checked })} />
      </div>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          width: 112,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        노출
        <Switch
          checked={spec.allowDisplay}
          onChange={(_, checked) => onChangeSpec({ ...spec, allowDisplay: checked })}
        />
      </div>
    </div>
  )
}

const DetailModelSpecCaseSizeField: FunctionComponent<{
  label: string
  specs: {
    "케이스 가로": { label: "케이스 가로"; value: string; allowDisplay: boolean; allowSearch: boolean }
    "케이스 세로": { label: "케이스 세로"; value: string; allowDisplay: boolean; allowSearch: boolean }
  }
  onChangeSpecs: (specs: {
    "케이스 가로": { label: "케이스 가로"; value: string; allowDisplay: boolean; allowSearch: boolean }
    "케이스 세로": { label: "케이스 세로"; value: string; allowDisplay: boolean; allowSearch: boolean }
  }) => void
}> = ({ label, specs, onChangeSpecs }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        padding: "8px 0",
        borderBottom: "1px solid #F0F2F5",
      }}
    >
      <div style={{ width: 120 }}>{label}</div>
      <div style={{ width: 8 }} />
      <div style={{ display: "inline-flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
        <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center" }}>
          가로
          <div style={{ width: 8 }} />
          <input
            style={{
              width: 80,
              height: 38,
              border: "none",
              outline: "none",
              fontSize: 14,
              fontFamily: "Pretendard",
              lineHeight: "18px",
            }}
            type="text"
            placeholder="ex) 40mm"
            value={specs["케이스 가로"].value}
            onChange={(event) =>
              onChangeSpecs({
                ...specs,
                "케이스 가로": { ...specs["케이스 가로"], value: event.target.value },
              })
            }
          />
        </div>
        <div style={{ margin: "0 24px 0 8px" }}>x</div>
        <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center" }}>
          세로(option)
          <div style={{ width: 8 }} />
          <input
            style={{
              width: 80,
              height: 38,
              border: "none",
              outline: "none",
              fontSize: 14,
              fontFamily: "Pretendard",
              lineHeight: "18px",
            }}
            type="text"
            placeholder="ex) 40mm"
            value={specs["케이스 세로"].value}
            onChange={(event) =>
              onChangeSpecs({
                ...specs,
                "케이스 세로": { ...specs["케이스 세로"], value: event.target.value },
              })
            }
          />
        </div>
      </div>

      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          width: 112,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        검색
        <Switch
          checked={specs["케이스 가로"].allowSearch}
          onChange={(_, checked) =>
            onChangeSpecs({
              ...specs,
              "케이스 가로": { ...specs["케이스 가로"], allowSearch: checked },
              "케이스 세로": { ...specs["케이스 세로"], allowSearch: checked },
            })
          }
        />
      </div>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          width: 112,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        노출
        <Switch
          checked={specs["케이스 가로"].allowDisplay}
          onChange={(_, checked) =>
            onChangeSpecs({
              ...specs,
              "케이스 가로": { ...specs["케이스 가로"], allowDisplay: checked },
              "케이스 세로": { ...specs["케이스 세로"], allowDisplay: checked },
            })
          }
        />
      </div>
    </div>
  )
}
