import styled from "@emotion/styled"
import { SuperLoginHistory } from "types/UserType"
import DetailTableBody from "components/Table/Detail/TableBody"
import React, { useEffect, useState } from "react"
import { getSuperLoginHistory } from "../../../../../apis/userAPI"
import { DateTime } from "luxon"

type Props = {
  userId: number
}

const UserSuperLoginHistoryTable = ({ userId }: Props) => {
  const [superLoginHistory, setSuperLoginHistory] = useState<SuperLoginHistory[]>([])
  const fetchSuperLoginHistory = (userId: number) => {
    getSuperLoginHistory(userId)
      .then(value => setSuperLoginHistory(value))
  }

  useEffect(() => {
    fetchSuperLoginHistory(userId)
  }, [userId])

  return (
    <DetailTableBody>
      <TitleWrapper>회원 계정으로 로그인 접속 이력</TitleWrapper>
      <table className={"basic"}>
        <thead>

        </thead>
        <tbody>
        <Tr>
          <Th className="header">접속 ID</Th>
          <Th className="header">접속 사유</Th>
          <Th className="header">접속 일시</Th>
        </Tr>
        {superLoginHistory.map(value => (
          <Tr key={value.adminUserName + value.loginedAt}>
            <Td>{value.adminUserName}</Td>
            <Td>{value.reason}</Td>
            <Td>{DateTime.fromISO(value.loginedAt).toFormat("yyyy-MM-dd HH:mm:ss")}</Td>
          </Tr>
        ))}
        </tbody>
      </table>


    </DetailTableBody>
  )
}

export default UserSuperLoginHistoryTable

const TitleWrapper = styled.div`
    padding: 10px;
    background: #eee;
    border-bottom: 1px solid #ccc;
    font-weight: 800;
`
const Tr = styled.tr`

`

const Th = styled.td`
    &.header {
        font-size: 0.8rem;
    }
`

const Td = styled.td`
    font-size: 0.825rem;
`