import styled from "@emotion/styled"
import {FormControl, MenuItem, Select} from "@mui/material"
import SearchBar from "components/Search/SearchBar"
import SearchInput from "components/Search/SearchBar/SearchInput"
import {CouponCodeListSearchParams, CouponCodeStatusCode} from "../../../../types/CouponCodeType";
import {CouponCodeListSearchType} from "../index";
import {UserListSearchParams} from "../../../../types/UserType";

const searchCouponCodeMenuList: { value: keyof CouponCodeListSearchParams; title: string }[] = [
  { value: "code", title: "코드" },
  { value: "name", title: "이름" },
  // { value: "status", title: "상태" },
]

const statusMenuList: { value: CouponCodeStatusCode; title: string }[] = [
  {
    value: CouponCodeStatusCode.활성,
    title: "활성",
  },
  {
    value: CouponCodeStatusCode.취소,
    title: "취소",
  },
]

type Props = {
  searchParams: CouponCodeListSearchType
  changeSearchKeyword: (keyword: string) => void
  changeSearchFilterValue: (filterValue: keyof CouponCodeListSearchParams) => void
  onSearch: (keyword: CouponCodeListSearchType["keyword"]) => void
}

const CouponCodeListTableSearchBar = ({ searchParams, changeSearchKeyword, changeSearchFilterValue, onSearch }: Props) => {
  return (
    <Wrapper>
      <FormControl style={{ minWidth: "150px" }}>
        <Select
          size={"small"}
          defaultValue={"code"}
          required
          onChange={(e) => changeSearchFilterValue(e.target.value as keyof CouponCodeListSearchParams)}
        >
          {searchCouponCodeMenuList.map((menu) => (
            <MenuItem value={menu.value.toString()} key={menu.title}>
              {menu.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SearchBar>
        <SearchInput
          style={{ height: 40 }}
          value={searchParams.keyword}
          onChange={(e) => changeSearchKeyword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch(searchParams.keyword)
            }
          }}
        />
      </SearchBar>
    </Wrapper>
  )
}

export default CouponCodeListTableSearchBar

const Wrapper = styled.div`
  width: 500px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`
