import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material"
import React, { useEffect, useReducer } from "react"
import BuyOrderTable from "./BuyOrderTable"
import { PageType } from "types/PageType"
import { BuyOrderListSearchParams, BuyOrderStatusCode, BuyOrderType } from "types/BuyOrderType"
import { useSearchParams } from "react-router-dom"
import { DateTime } from "luxon"
import { CountType } from "types/CountType"
import Counters from "../SellOrder/Counters"
import { DesktopDatePicker } from "@mui/lab"
import ViewBuyOrderPopup from "./ViewBuyOrderPopup"
import { countBuyOrders, getBuyOrders, getBuyOrderList } from "apis/buyOrderAPI"
import { toApplyCounts } from "utils/SellOrderTypeConverter"
import ShippingRequestPopup from "components/Popup/ShippingRequestPopup"
import { SaleTypeCode } from "types/ProductType"
import { getShippings } from "./utils"
import { downloadExcel } from "utils/excelUtil";
import { toDateTimeStr } from "../../utils/datetimeUtil"
import { toast } from "react-toastify"


export interface BuyOrderState {
  selectedIds: string[]
  buyOrders: PageType<BuyOrderType>
  buyOrderCount: CountType[]
  isShowViewPopup: boolean
  isShippingRequestPopupOpen: boolean
  editBuyOrder?: BuyOrderType
  startDateFilter?: DateTime
  endDateFilter?: DateTime
  searchFilterKey?: keyof BuyOrderListSearchParams
  searchFilterValue?: string
  foreigner?: string
  refresh: boolean
  loading: boolean
}

const initState: BuyOrderState = {
  selectedIds: [],
  buyOrders: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  buyOrderCount: [],
  isShowViewPopup: false,
  isShippingRequestPopupOpen: false,
  editBuyOrder: undefined,
  startDateFilter: undefined,
  endDateFilter: undefined,
  searchFilterKey: "productId",
  searchFilterValue: undefined,
  foreigner : "A",
  refresh: false,
  loading: false,
}

interface BuyOrderAction {
  type: string
  payload?: any
}

function reducer(state: BuyOrderState, action: BuyOrderAction): BuyOrderState {
  switch (action.type) {
    case "SELECTION_BUYORDER_IDS":
      return { ...state, selectedIds: action.payload }
    case "FETCH_BUYORDER":
      return { ...state, buyOrders: action.payload }
    case "FETCH_BUYORDER_COUNT":
      return { ...state, buyOrderCount: action.payload }
    case "CHANGE_START_DATE":
      return { ...state, startDateFilter: action.payload }
    case "CHANGE_END_DATE":
      return { ...state, endDateFilter: action.payload }
    case "SHOW_VIEW_BUYORDER_POPUP":
      return { ...state, isShowViewPopup: true, editBuyOrder: action.payload }
    case "HIDE_VIEW_BUYORDER_POPUP":
      return { ...state, isShowViewPopup: false }
    case "SHOW_SHIPPING_REQUEST_POPUP_OPEN":
      return { ...state, isShippingRequestPopupOpen: true }
    case "HIDE_SHIPPING_REQUEST_POPUP_OPEN":
      return { ...state, isShippingRequestPopupOpen: false }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: "" }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
    case "CHANGE_FOREIGNER":
      return { ...state, foreigner : action.payload, refresh: !state.refresh }
    case "SEARCH":
      return { ...state, refresh: !state.refresh }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }

  return state
}

const BuyOrders = () => {
  const productSaleType: SaleTypeCode[] = [
    SaleTypeCode.직접판매,
    SaleTypeCode.위탁판매,
    SaleTypeCode.진단전판매,
    SaleTypeCode.매입보증판매,
  ]
  const buyOrderStatus: BuyOrderStatusCode[] = [
    BuyOrderStatusCode.결제_대기,
    BuyOrderStatusCode.상품_준비,
    BuyOrderStatusCode.진단중,
    BuyOrderStatusCode.배송_준비,
    BuyOrderStatusCode.배송중,
    BuyOrderStatusCode.구매_완료,
    BuyOrderStatusCode.구매_취소,
    BuyOrderStatusCode.반품_요청완료,
  ]

  const [state, dispatch] = useReducer(reducer, initState)
  const [currentSearchParams] = useSearchParams()

  const fetchBuyOrders = (searchParams: BuyOrderListSearchParams = {}) => {
    let newParams: BuyOrderListSearchParams = {
      ...searchParams,
      productSaleType: productSaleType,
      status: (currentSearchParams.get("status")?.split(",") as BuyOrderStatusCode[]) || buyOrderStatus,
      productId: currentSearchParams.get("productId") ?? undefined,
      buyerId: currentSearchParams.get("buyerId") ?? undefined,
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate(),
      sellerId: currentSearchParams.get("sellerId") ?? undefined,
      foreigner: state.foreigner === "Y" ? "true" : state.foreigner === "N" ? "false" : undefined,
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    dispatch({ type: "START_LOADING" })
    getBuyOrders(newParams)
      .then((buyOrders) => dispatch({ type: "FETCH_BUYORDER", payload: buyOrders }))
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  const fetchSellOrderCounts = () => {
    countBuyOrders({
      ...currentSearchParams,
      status: buyOrderStatus,
      productSaleType: productSaleType,
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate(),
      foreigner: state.foreigner === "Y" ? "true" : state.foreigner === "N" ? "false" : undefined,
    }).then((result) => {
      dispatch({
        type: "FETCH_BUYORDER_COUNT",
        payload: toApplyCounts(result, buyOrderStatus || [], "."),
      })
    })
  }

  const handleExcelDownloadClick = () => {

    if (state.startDateFilter === undefined) {
      toast.error("시작일을 선택해주세요")
      return false
    }
    if (state.endDateFilter === undefined) {
      toast.error("종료일을 선택해주세요")
      return false
    }

    let newParams: BuyOrderListSearchParams = {
      productSaleType: productSaleType,
      status: (currentSearchParams.get("status")?.split(",") as BuyOrderStatusCode[]) || buyOrderStatus,
      productId: currentSearchParams.get("productId") ?? undefined,
      buyerId: currentSearchParams.get("buyerId") ?? undefined,
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate(),
      sellerId: currentSearchParams.get("sellerId") ?? undefined,
      foreigner: state.foreigner === "Y" ? "true" : state.foreigner === "N" ? "false" : undefined,
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    getBuyOrderList(newParams)
        .then(buyOrders => {
          let data = buyOrders.map(it => {
            return {
              "상품번호": it.product?.id,
              "구매주문번호": it.id,
              "진행상태": it.buyOrderStatus,
              "판매방식": it.product?.saleType,
              "수령방식": it.shipping?.shippingMethod?.replace('안전배송_2', '일양택배')?.replace('안전배송', '발렉스'),
              "정밀진단": it.advancedDiagnosis ? "O" : "X",
              "판매자 ID": it.product?.sellerId,
              "상품명": `${it?.product?.title} ${it?.product?.titleSecondary}`,
              "제품상태":  it?.product?.productCondition,
              "Ref No.": it?.product?.detailModel?.fullRefNo,
              "구매가": it?.productPrice,
              "결제 금액": it?.paymentAmount,
              "구매자ID": it?.buyer?.id,
              "외국인": it?.buyer?.foreigner ? "Y" : "N",
              "구매일": `${toDateTimeStr(it?.orderedAt) ?? ""}`,
              "결제일": `${toDateTimeStr(it?.payment?.transactionAt) ?? ""}`,
              "진행상태 변경일": `${toDateTimeStr(it?.completedAt || it?.canceledAt || it?.completeShippingAt || it?.startShippingAt || it?.orderedAt) ?? ""}`,
              "스탬핑": it?.product?.stampingDate
            }
          })
          downloadExcel(data, "구매주문")
        })
  }

  useEffect(() => {
    fetchBuyOrders({
      page: state.buyOrders.pageable?.pageNumber || 0,
      size: state.buyOrders.pageable?.pageSize || 25,
    })
    fetchSellOrderCounts()
  }, [state.refresh, currentSearchParams])

  return (
    <Grid container spacing={2}>
      <ViewBuyOrderPopup
        open={state.isShowViewPopup}
        selectedBuyOrderId={state.editBuyOrder?.id}
        handleClose={() => {
          dispatch({ type: "HIDE_VIEW_BUYORDER_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <ShippingRequestPopup
        open={state.isShippingRequestPopupOpen}
        type={"NORMAL"}
        selectedShipping={getShippings(state.buyOrders.content, state.selectedIds)}
        handleClose={() => {
          dispatch({ type: "HIDE_SHIPPING_REQUEST_POPUP_OPEN" })
          dispatch({ type: "REFRESH" })
        }}
      />
      {
        <Grid item xs={12}>
          <Counters params={state.buyOrderCount} selectedLabel={currentSearchParams.get("status")} />
        </Grid>
      }
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <Stack spacing={1} direction={"column"}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="search_key">검색항목</InputLabel>
                    <Select
                      value={state.searchFilterKey || ""}
                      onChange={(params) => {
                        dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                      }}
                    >
                      <MenuItem value={"id"}>구매주문번호</MenuItem>
                      <MenuItem value={"productId"}>상품번호</MenuItem>
                      <MenuItem value={"shippingMethod"}>수령방식</MenuItem>
                      <MenuItem value={"productTitle"}>상품명</MenuItem>
                      <MenuItem value={"buyerName"}>구매자 이름</MenuItem>
                      <MenuItem value={"buyerPhone"}>구매자 연락처</MenuItem>
                      <MenuItem value={"sellerId"}>판매자 ID</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: "220px" }}
                    size={"small"}
                    value={state.searchFilterValue || ""}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch({ type: "SEARCH" })
                      }
                    }}
                    onChange={(e) => {
                      dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: e.target.value })
                    }}
                  />
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="search_key">외국인</InputLabel>
                    <Select
                      value={state.foreigner || ""}
                      onChange={(params) => {
                        dispatch({ type: "CHANGE_FOREIGNER", payload: params.target.value })
                      }}
                    >
                      <MenuItem value={"A"}>전체</MenuItem>
                      <MenuItem value={"Y"}>외국인</MenuItem>
                      <MenuItem value={"N"}>내국인</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack spacing={1} direction={"row"} sx={{ lineHeight: "45px" }}>
                  <Button variant={"outlined"} size={"small"} style={{ margin: "0 2px" }} onClick={handleExcelDownloadClick}>엑셀 다운로드</Button>
                  <DesktopDatePicker
                    label="구매 시작일"
                    inputFormat="yyyy-MM-dd"
                    value={state.startDateFilter ?? null}
                    onChange={(params) => {
                      dispatch({ type: "CHANGE_START_DATE", payload: params })
                      dispatch({ type: "REFRESH" })
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                  />
                  <DesktopDatePicker
                    label="구매 종료일"
                    inputFormat="yyyy-MM-dd"
                    value={state.endDateFilter ?? null}
                    onChange={(params) => {
                      dispatch({ type: "CHANGE_END_DATE", payload: params })
                      dispatch({ type: "REFRESH" })
                    }}
                    renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <BuyOrderTable state={state} dispatch={dispatch} fetch={fetchBuyOrders} components={[]} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default BuyOrders
