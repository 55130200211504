import React, { useCallback, useEffect, useState } from "react"
import { Button, Divider, Grid, Input, Paper, Stack, Switch, TextField, Typography } from "@mui/material"
import {
  PaymentAreaComponentType,
  PaymentAreaComponentTypeCode,
  PaymentAreaMessageLevelCode,
  PaymentAreaPaymentComponentTypeCode,
  PaymentBannerComponentType,
  PaymentCardBenefitComponentType,
  PaymentDiscountNoticeComponentType,
  PaymentMessageComponentType,
  PaymentMethodComponentType,
  PaymentMethodEditable,
} from "types/PaymentAreaComponentType"
import { toast } from "react-toastify"
import {
  createCardBenefitComponent,
  createDiscountNoticeComponent,
  createMessageComponent,
  deleteCardBenefitComponent,
  deleteDiscountNoticeComponent,
  deleteMessageComponent,
  getComponents,
  updateCardBenefitComponent,
  updateDiscountNoticeComponent,
  updateMessageComponent,
  updatePaymentMethodComponent,
} from "../../apis/paymentAreaAPI"
import { SaleTypeCode } from "../../types/ProductType"
import PaidIcon from "@mui/icons-material/Paid"
import { isNil } from "../../utils/validationUtils"
import { DateTimePicker } from "@mui/lab"
import { DateTime } from "luxon"
import { BoxHorizontal, BoxVertical } from "@viver-inc/viver-ui"
import { EditableImage } from "../ModelRegistrationRequestManager/ModelRegistrationRequestFormPopup/EditableImage"
import { UploadButton } from "./UploadButton"
import styled from "@emotion/styled"
import { upload } from "../../apis/resourceAPI"

const NoImageIconSrc = require("images/no_image.gif")

const Wrapper = styled.div`
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 4px;
    border: 1px solid #ced2d6;
    overflow: hidden;
`

const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
`

export const PaymentAreaManager: React.FC = () => {
  const [saleType, setSaleType] = useState<SaleTypeCode>(SaleTypeCode.진단전판매)
  const [promotionComponents, setPromotionComponents] = useState<PaymentMessageComponentType[]>([])
  const [messageComponents, setMessageComponents] = useState<PaymentMessageComponentType[]>([])
  const [paymentMethodComponents, setPaymentMethodComponents] = useState<PaymentMethodComponentType[]>([])
  const [discountNoticeComponents, setDiscountNoticeComponents] = useState<PaymentDiscountNoticeComponentType[]>([])
  const [cardBenefitComponents, setCardBenefitComponents] = useState<PaymentCardBenefitComponentType[]>([])

  const fetchComponents = useCallback(() => {
    getComponents().then((components) => {
      const promotionComponents: PaymentMessageComponentType[] = []
      const messageComponents: PaymentMessageComponentType[] = []
      const paymentMethodComponents: PaymentMethodComponentType[] = []
      const discountNoticeComponents: PaymentDiscountNoticeComponentType[] = []
      const cardBenefitComponents: PaymentCardBenefitComponentType[] = []
      const bannerComponents: PaymentBannerComponentType[] = []

      components.forEach((component) => {
        if (component.componentType === PaymentAreaComponentTypeCode.배너) {
          bannerComponents.push(component as PaymentBannerComponentType)
        } else if (component.componentType === PaymentAreaComponentTypeCode.메시지) {
          if (component.messageLevel === PaymentAreaMessageLevelCode.프로모션) {
            promotionComponents.push(component as PaymentMessageComponentType)
          } else {
            messageComponents.push(component as PaymentMessageComponentType)
          }
        } else if (component.componentType === PaymentAreaComponentTypeCode.결제수단) {
          var paymentMethodComponent = component as PaymentMethodComponentType
          paymentMethodComponent.paymentMethodEditable = PaymentMethodEditable(component.paymentMethodType)
          paymentMethodComponents.push(paymentMethodComponent)
        } else if (component.componentType === PaymentAreaComponentTypeCode.즉시할인) {
          discountNoticeComponents.push(component as PaymentDiscountNoticeComponentType)
        } else if (component.componentType === PaymentAreaComponentTypeCode.카드사혜택) {
          cardBenefitComponents.push(component as PaymentCardBenefitComponentType)
        }
      })

      setMessageComponents(messageComponents)
      setPromotionComponents(promotionComponents)
      setPaymentMethodComponents(paymentMethodComponents)
      setDiscountNoticeComponents(discountNoticeComponents)
      setCardBenefitComponents(cardBenefitComponents)
    })
  }, [saleType])

  const updatePaymentMethod = (id: number, component?: PaymentAreaComponentType) => {
    updatePaymentMethodComponent(id, { ...component })
      .then((_) => toast.success("변경이 완료되었습니다."))
      .catch((ex) => {
        toast.error("변경에 실패했습니다.")
        console.error(ex)
      })
  }

  const updateMessage = (component: PaymentMessageComponentType) => {
    if (isNil(component.title)) {
      toast.error("내용을 입력해주세요.")
      return
    }

    if (component.messageLevel === PaymentAreaMessageLevelCode.프로모션 && isNil(component.linkUrl)) {
      toast.error("랜딩 이미지를 등록해주세요.")
      return
    }

    if (isNew(component.id)) {
      createMessageComponent(component)
        .then((result) => {
          setMessageComponents((prev) =>
            prev.map((prevComponent) =>
              prevComponent.id === component.id ? { ...component, id: result.id } : prevComponent,
            ),
          )
          toast.success("등록이 완료되었습니다.")
        })
        .catch((ex) => {
          toast.error("등록에 실패했습니다.")
          console.error(ex)
        })
    } else {
      updateMessageComponent(component.id, { ...component })
        .then((_) => toast.success("변경이 완료되었습니다."))
        .catch((ex) => {
          toast.error("변경에 실패했습니다.")
          console.error(ex)
        })
    }
  }

  const deleteMessage = (component: PaymentMessageComponentType) => {
    if (!isNew(component.id)) {
      deleteMessageComponent(component.id)
        .then((_) => {
          toast.success("삭제 되었습니다.")
        })
        .catch((ex) => {
          toast.error("삭제하지 못했습니다.")
          console.error(ex)
        })
    }
    messageComponents.splice(messageComponents.indexOf(component), 1)
    setMessageComponents([...messageComponents])
  }

  const updateDiscountNotice = (component: PaymentDiscountNoticeComponentType) => {
    if (isNil(component.description)) {
      toast.error("내용을 입력해주세요.")
      return
    }

    if (isNew(component.id)) {
      createDiscountNoticeComponent(component)
        .then((result) => {
          setDiscountNoticeComponents((prev) =>
            prev.map((prevComponent) =>
              prevComponent.id === component.id ? { ...component, id: result.id } : prevComponent,
            ),
          )
          toast.success("등록이 완료되었습니다.")
        })
        .catch((ex) => {
          toast.error("등록에 실패했습니다.")
          console.error(ex)
        })
    } else {
      updateDiscountNoticeComponent(component.id, { ...component })
        .then((_) => toast.success("변경이 완료되었습니다."))
        .catch((ex) => {
          toast.error("변경에 실패했습니다.")
          console.error(ex)
        })
    }
  }

  const deleteDiscountNotice = (component: PaymentDiscountNoticeComponentType) => {
    if (!isNew(component.id)) {
      deleteDiscountNoticeComponent(component.id)
        .then((_) => {
          toast.success("삭제 되었습니다.")
        })
        .catch((ex) => {
          toast.error("삭제하지 못했습니다.")
          console.error(ex)
        })
    }
    discountNoticeComponents.splice(discountNoticeComponents.indexOf(component), 1)
    setDiscountNoticeComponents([...discountNoticeComponents])
  }

  const updateCardBenefit = (component: PaymentCardBenefitComponentType) => {
    if (isNil(component.title)) {
      toast.error("제목을 입력해주세요.")
      return
    }
    if (isNil(component.description)) {
      toast.error("상세 설명을 입력해주세요.")
      return
    }

    if (isNew(component.id)) {
      createCardBenefitComponent(component)
        .then((result) => {
          setCardBenefitComponents((prev) =>
            prev.map((prevComponent) =>
              prevComponent.id === component.id ? { ...component, id: result.id } : prevComponent,
            ),
          )
          toast.success("등록이 완료되었습니다.")
        })
        .catch((ex) => {
          toast.error("등록에 실패했습니다.")
          console.error(ex)
        })
    } else {
      updateCardBenefitComponent(component.id, { ...component })
        .then((_) => toast.success("변경이 완료되었습니다."))
        .catch((ex) => {
          toast.error("변경에 실패했습니다.")
          console.error(ex)
        })
    }
  }

  const deleteCardBenefit = (component: PaymentCardBenefitComponentType) => {
    if (!isNew(component.id)) {
      deleteCardBenefitComponent(component.id)
        .then((_) => {
          toast.success("삭제 되었습니다.")
        })
        .catch((ex) => {
          toast.error("삭제하지 못했습니다.")
          console.error(ex)
        })
    }
    cardBenefitComponents.splice(cardBenefitComponents.indexOf(component), 1)
    setCardBenefitComponents([...cardBenefitComponents])
  }

  const uploadImage = async (image: FileList, component: PaymentAreaComponentType, type?: string) => {
    if (isNil(image)) return
    try {
      const result = await upload(image)

      if (component.componentType === PaymentAreaComponentTypeCode.메시지 && component.messageLevel === PaymentAreaMessageLevelCode.프로모션) {
        setPromotionComponents((prev) =>
          prev.map((prevComponent) =>
            prevComponent.id === component.id ? { ...prevComponent, linkUrl: result.url } : prevComponent,
          ),
        )
      } else if (component.componentType === PaymentAreaComponentTypeCode.카드사혜택) {
        setCardBenefitComponents((prev) =>
          prev.map((prevComponent) =>
            prevComponent.id === component.id ? { ...prevComponent, linkUrl: result.url } : prevComponent,
          ),
        )
      }
    } catch {
      toast.error("이미지 업로드 실패")
    }
  }


  const isNew = (id: any) => isNil(id) || id <= 0

  useEffect(() => {
    fetchComponents()
  }, [fetchComponents, saleType])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "row", alignItems: "start" }}>
          <Grid item xs={1}>
            <Stack spacing={3} direction={"column"} sx={{ pt: 3, pb: 3, pl: 2 }}>
              <Typography
                style={{ fontWeight: 800, textDecoration: "none", color: "black" }}>
                프로모션
              </Typography>
              <Button
                variant={"outlined"}
                size={"small"}
                sx={{ ml: 1, width: "50%" }}
                onClick={() => {
                  setPromotionComponents([...promotionComponents, {
                    id: -1 * (promotionComponents.length),
                    componentType: PaymentAreaComponentTypeCode.메시지,
                    messageLevel: PaymentAreaMessageLevelCode.프로모션,
                  } as PaymentMessageComponentType])
                }}>
                추가
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={11}>
            <Stack spacing={3} direction={"column"} justifyContent="flex-start" sx={{ pt: 3, pb: 3, pl: 2 }} divider={<Divider orientation="horizontal" flexItem />}>
              {promotionComponents.map((component, idx) => {
                return (
                  <Stack direction={"row"} justifyContent={"justify-between"}>
                    <Stack sx={{ alignItems: "center" }} justifyContent="center">
                      <Switch
                        checked={component.onDisplay}
                        onChange={(event) => {
                          const changedComponent = { ...component, onDisplay: event.target.checked }
                          setPromotionComponents((prev) =>
                            prev.map((prevComponent) =>
                              prevComponent.id === component.id ? changedComponent : prevComponent,
                            ),
                          )
                        }}
                      />
                    </Stack>
                    <Stack direction={"column"} justifyContent="center" spacing={1}>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Input
                          sx={{ width: 400 }}
                          multiline
                          value={component.title}
                          onChange={(event) => {
                            setPromotionComponents((prev) =>
                              prev.map((prevComponent) =>
                                prevComponent.id === component.id
                                  ? { ...component, title: event.target.value }
                                  : prevComponent,
                              ),
                            )
                          }}
                        />
                      </Stack>

                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <DateTimePicker
                          onChange={(event) => {
                            const changedComponent = { ...component, startAt: event?.toISO() }
                            setPromotionComponents((prev) =>
                              prev.map((prevComponent) =>
                                prevComponent.id === component.id ? changedComponent : prevComponent,
                              ),
                            )
                          }}
                          value={(component.startAt && DateTime.fromISO(component.startAt)) || null}
                          inputFormat={"yyyy-MM-dd HH:mm"}
                          renderInput={(props) => (<TextField size={"small"} sx={{ width: "40%" }} {...props} />)}
                          label={"게시 시작"} />
                        <Typography sx={{ mr: 3, ml: 3 }}>~</Typography>
                        <DateTimePicker
                          onChange={(event) => {
                            const changedComponent = { ...component, endAt: event?.toISO() }
                            setPromotionComponents((prev) =>
                              prev.map((prevComponent) =>
                                prevComponent.id === component.id ? changedComponent : prevComponent,
                              ),
                            )
                          }}
                          value={(component.endAt && DateTime.fromISO(component.endAt)) || null}
                          inputFormat={"yyyy-MM-dd HH:mm"}
                          renderInput={(props) => (<TextField size={"small"} sx={{ width: "40%" }} {...props} />)}
                          label={"게시 종료"} />
                      </Stack>

                    </Stack>
                    <Stack direction={"row"}>
                      <div className="inlineWrapper">
                        <BoxVertical flex={1} paddingHorizontal={10}>
                          <BoxHorizontal alignItems="center">
                            {!!component.linkUrl && (
                              <EditableImage
                                url={!component.linkUrl ? NoImageIconSrc : component.linkUrl}
                                deleteImage={() => {
                                  setPromotionComponents((prev) =>
                                    prev.map((prevComponent) =>
                                      prevComponent.id === component.id ? {
                                        ...component,
                                        linkUrl: undefined,
                                      } : prevComponent,
                                    ),
                                  )
                                }}
                              />
                            )}
                            {!component.linkUrl && (
                              <Wrapper>
                                <Image src={NoImageIconSrc} alt="close icon" />
                              </Wrapper>
                            )}
                            <BoxVertical flex={1} justifyContent="center" paddingLeft={20}>
                              <span>랜딩 이미지</span>
                              <BoxHorizontal alignItems="center" paddingVertical={5}>
                                <UploadButton
                                  handleImage={(files) => {
                                    uploadImage(files, component)
                                  }}
                                  text={"이미지첨부"}
                                  variant={"outlined"}
                                />
                              </BoxHorizontal>
                            </BoxVertical>
                          </BoxHorizontal>
                        </BoxVertical>
                      </div>
                      <Stack direction={"column"} justifyContent="center">
                        <Button
                          sx={{ ml: 1 }}
                          color={isNew(component.id) ? "success" : "primary"}
                          variant={"contained"}
                          size={"small"}
                          onClick={() => {
                            updateMessage(component)
                          }}
                        >
                          {isNew(component.id) ? "등록" : "저장"}
                        </Button>
                        <Button
                          sx={{ ml: 1 }}
                          color={"error"}
                          variant={"contained"}
                          size={"small"}
                          onClick={() => {
                            deleteMessage(component)
                          }}
                        >
                          삭제
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>)
              })}
            </Stack>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Grid item xs={1}>
            <Stack spacing={1} direction={"column"} justifyContent="flex-start" sx={{ pt: 3, pb: 3, pl: 2 }}>
              <Typography style={{ fontWeight: 800, textDecoration: "none", color: "black" }}>결제수단</Typography>
            </Stack>
          </Grid>
          <Grid item xs={11}>
            <Stack spacing={1} direction={"row"} justifyContent="flex-start" sx={{ pt: 3, pb: 3, pl: 2 }}>
              {paymentMethodComponents.map((component, idx) => {
                return (
                  <Stack direction={"row"} sx={{ alignItems: "center" }}>
                    <Switch
                      checked={component.onDisplay}
                      onChange={(event) => {
                        const changedComponent = { ...component, onDisplay: event.target.checked }
                        setPaymentMethodComponents((prev) =>
                          prev.map((prevComponent) =>
                            prevComponent.id === component.id ? changedComponent : prevComponent,
                          ),
                        )
                        updatePaymentMethod(component.id, changedComponent)
                      }}
                      disabled={!component.paymentMethodEditable}
                    />
                    <PaidIcon sx={{ alignItems: "center", mr: 1 }} />
                    {component.paymentMethodType == PaymentAreaPaymentComponentTypeCode.결제위젯 ? "일반결제" : component.paymentMethodType}
                  </Stack>
                )
              })}
            </Stack>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "row", alignItems: "start" }}>
          <Grid item xs={1}>
            <Stack spacing={3} direction={"column"} sx={{ pt: 3, pb: 3, pl: 2 }}>
              <Typography
                style={{ fontWeight: 800, textDecoration: "none", color: "black" }}>
                공지사항
              </Typography>
              <Button
                variant={"outlined"}
                size={"small"}
                sx={{ ml: 1, width: "50%" }}
                onClick={() => {
                  setMessageComponents([...messageComponents, {
                    id: -1 * (messageComponents.length),
                    messageLevel: PaymentAreaMessageLevelCode.공지사항,
                  } as PaymentMessageComponentType])
                }}>
                추가
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={11}>
            <Stack spacing={3} direction={"column"} justifyContent="flex-start" sx={{ pt: 3, pb: 3, pl: 2 }} divider={<Divider orientation="horizontal" flexItem />}>
              {messageComponents.map((component, idx) => {
                return (
                  <Stack direction={"row"} justifyContent={"justify-between"}>
                    <Stack sx={{ alignItems: "center" }} justifyContent="center">
                      <Switch
                        checked={component.onDisplay}
                        onChange={(event) => {
                          const changedComponent = { ...component, onDisplay: event.target.checked }
                          setMessageComponents((prev) =>
                            prev.map((prevComponent) =>
                              prevComponent.id === component.id ? changedComponent : prevComponent,
                            ),
                          )
                        }}
                      />
                    </Stack>
                    <Stack direction={"row"} sx={{ alignItems: "center" }} justifyContent={"center"}>
                      <Input
                        sx={{ width: 400, mr: 3 }}
                        multiline
                        value={component.title}
                        onChange={(event) => {
                          setMessageComponents((prev) =>
                            prev.map((prevComponent) =>
                              prevComponent.id === component.id
                                ? { ...component, title: event.target.value }
                                : prevComponent,
                            ),
                          )
                        }}
                      />
                      <Stack direction={"column"} sx={{ alignItems: "center" }} spacing={1}>
                        <DateTimePicker
                          label={"게시 시작"}
                          onChange={(event) => {
                            const changedComponent = { ...component, startAt: event?.toISO() }
                            setMessageComponents((prev) =>
                              prev.map((prevComponent) =>
                                prevComponent.id === component.id ? changedComponent : prevComponent,
                              ),
                            )
                          }}
                          value={(component.startAt && DateTime.fromISO(component.startAt)) || null}
                          inputFormat={"yyyy-MM-dd HH:mm"}
                          renderInput={(props) => <TextField size={"small"} sx={{ width: "100%" }} {...props} />}
                        />
                        <DateTimePicker
                          onChange={(event) => {
                            const changedComponent = { ...component, endAt: event?.toISO() }
                            setMessageComponents((prev) =>
                              prev.map((prevComponent) =>
                                prevComponent.id === component.id ? changedComponent : prevComponent,
                              ),
                            )
                          }}
                          value={(component.endAt && DateTime.fromISO(component.endAt)) || null}
                          inputFormat={"yyyy-MM-dd HH:mm"}
                          renderInput={(props) => (<TextField size={"small"} sx={{ width: "100%" }} {...props} />)}
                          label={"게시 종료"} />
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} justifyContent="flex-end">
                      <Stack direction={"column"} justifyContent="center">
                        <Button
                          sx={{ ml: 1 }}
                          color={isNew(component.id) ? "success" : "primary"}
                          variant={"contained"}
                          size={"small"}
                          onClick={() => {
                            updateMessage(component)
                          }}
                        >
                          {isNew(component.id) ? "등록" : "저장"}
                        </Button>
                        <Button
                          sx={{ ml: 1 }}
                          color={"error"}
                          variant={"contained"}
                          size={"small"}
                          onClick={() => {
                            deleteMessage(component)
                          }}
                        >
                          삭제
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                )
              })}
            </Stack>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "row", alignItems: "start" }}>
          <Grid item xs={1}>
            <Stack spacing={3} direction={"column"} sx={{ pt: 3, pb: 3, pl: 2 }}>
              <Typography style={{ fontWeight: 800, textDecoration: "none", color: "black" }}>
                즉시할인
              </Typography>
              <Button
                variant={"outlined"}
                size={"small"}
                sx={{ width: "50%" }}
                onClick={() => {
                  setDiscountNoticeComponents([...discountNoticeComponents, {
                    id: -1 * (discountNoticeComponents.length),
                    componentType: PaymentAreaComponentTypeCode.즉시할인,
                  } as PaymentDiscountNoticeComponentType])
                }}>
                추가
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={11}>
            <Stack spacing={3} direction={"column"} justifyContent="flex-start" sx={{ pt: 3, pb: 3, pl: 2 }} divider={<Divider orientation="horizontal" flexItem />}>
              {discountNoticeComponents.map((component, idx) => {
                return (
                  <Stack direction={"row"}>
                    <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
                      <Switch
                        checked={component.onDisplay}
                        onChange={(event) => {
                          const changedComponent = { ...component, onDisplay: event.target.checked }
                          setDiscountNoticeComponents((prev) =>
                            prev.map((prevComponent) =>
                              prevComponent.id === component.id ? changedComponent : prevComponent,
                            ),
                          )
                        }}
                      />
                      <Input
                        sx={{ ml: 3, width: 400 }}
                        multiline
                        value={component.description}
                        onChange={(event) => {
                          setDiscountNoticeComponents((prev) =>
                            prev.map((prevComponent) =>
                              prevComponent.id === component.id
                                ? { ...component, description: event.target.value }
                                : prevComponent,
                            ),
                          )
                        }}
                      />
                      <Stack direction={"row"} alignItems={"center"} spacing={2} justifyContent={"center"}>
                        <DateTimePicker
                          label={"게시 시작"}
                          onChange={(event) => {
                            const changedComponent = { ...component, startAt: event?.toISO() }
                            setDiscountNoticeComponents((prev) =>
                              prev.map((prevComponent) =>
                                prevComponent.id === component.id ? changedComponent : prevComponent,
                              ),
                            )
                          }}
                          value={(component.startAt && DateTime.fromISO(component.startAt)) || null}
                          inputFormat={"yyyy-MM-dd HH:mm"}
                          renderInput={(props) => <TextField size={"small"} sx={{ width: "45%" }} {...props} />}
                        />
                        <DateTimePicker
                          onChange={(event) => {
                            const changedComponent = { ...component, endAt: event?.toISO() }
                            setDiscountNoticeComponents((prev) =>
                              prev.map((prevComponent) =>
                                prevComponent.id === component.id ? changedComponent : prevComponent,
                              ),
                            )
                          }}
                          value={(component.endAt && DateTime.fromISO(component.endAt)) || null}
                          inputFormat={"yyyy-MM-dd HH:mm"}
                          renderInput={(props) => (<TextField size={"small"} sx={{ width: "45%" }} {...props} />)}
                          label={"게시 종료"} />
                      </Stack>
                    </Stack>
                    <Stack direction={"column"} sx={{ alignItems: "center" }}>
                      <Button
                        sx={{ ml: 1 }}
                        color={isNew(component.id) ? "success" : "primary"}
                        variant={"contained"}
                        size={"small"}
                        onClick={() => {
                          updateDiscountNotice(component)
                        }}
                      >
                        {isNew(component.id) ? "등록" : "저장"}
                      </Button>
                      <Button
                        sx={{ ml: 1 }}
                        color={"error"}
                        variant={"contained"}
                        size={"small"}
                        onClick={() => {
                          deleteDiscountNotice(component)
                        }}
                      >
                        삭제
                      </Button>
                    </Stack>
                  </Stack>)
              })}
            </Stack>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "row", alignItems: "start" }}>
          <Grid item xs={1}>
            <Stack spacing={3} direction={"column"} sx={{ pt: 3, pb: 3, pl: 2 }}>
              <Typography style={{ fontWeight: 800, textDecoration: "none", color: "black" }}>
                카드혜택
              </Typography>
              <Button
                sx={{ ml: 1, width: "50%" }}
                variant={"outlined"}
                size={"small"}
                onClick={() => {
                  setCardBenefitComponents([...cardBenefitComponents, {
                    id: -1 * (cardBenefitComponents.length),
                    componentType: PaymentAreaComponentTypeCode.카드사혜택,
                  } as PaymentCardBenefitComponentType])
                }}>
                추가
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={11}>
            <Stack spacing={3} direction={"column"} justifyContent="flex-start" sx={{ pt: 3, pb: 3, pl: 2 }} divider={<Divider orientation="horizontal" flexItem />}>
              {cardBenefitComponents.map((component, idx) => {
                return (
                  <Stack direction={"row"}>
                    <Stack sx={{ alignItems: "center" }} justifyContent="center">
                      <Switch
                        checked={component.onDisplay}
                        onChange={(event) => {
                          const changedComponent = { ...component, onDisplay: event.target.checked }
                          setCardBenefitComponents((prev) =>
                            prev.map((prevComponent) =>
                              prevComponent.id === component.id ? changedComponent : prevComponent,
                            ),
                          )
                        }}
                      />
                    </Stack>

                    <Stack direction={"column"} spacing={1}>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Input
                          sx={{ width: 400 }}
                          placeholder={"제목"}
                          value={component.title}
                          onChange={(event) => {
                            setCardBenefitComponents((prev) =>
                              prev.map((prevComponent) =>
                                prevComponent.id === component.id
                                  ? { ...component, title: event.target.value }
                                  : prevComponent,
                              ),
                            )
                          }}
                        />
                      </Stack>
                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Input
                          sx={{ width: 600 }}
                          placeholder={"상세설명"}
                          value={component.description}
                          onChange={(event) => {
                            setCardBenefitComponents((prev) =>
                              prev.map((prevComponent) =>
                                prevComponent.id === component.id
                                  ? { ...component, description: event.target.value }
                                  : prevComponent,
                              ),
                            )
                          }}
                        />
                      </Stack>

                      <Stack direction={"row"} sx={{ alignItems: "center" }}>
                        <Stack direction={"column"} sx={{ alignItems: "center" }} spacing={1}>
                          <DateTimePicker
                            onChange={(event) => {
                              const changedComponent = { ...component, startAt: event?.toISO() }
                              setCardBenefitComponents((prev) =>
                                prev.map((prevComponent) =>
                                  prevComponent.id === component.id ? changedComponent : prevComponent,
                                ),
                              )
                            }}
                            value={(component.startAt && DateTime.fromISO(component.startAt)) || null}
                            inputFormat={"yyyy-MM-dd HH:mm"}
                            renderInput={(props) => (<TextField size={"small"} sx={{ width: "100%" }} {...props} />)}
                            label={"게시 시작"} />
                          <DateTimePicker
                            onChange={(event) => {
                              const changedComponent = { ...component, endAt: event?.toISO() }
                              setCardBenefitComponents((prev) =>
                                prev.map((prevComponent) =>
                                  prevComponent.id === component.id ? changedComponent : prevComponent,
                                ),
                              )
                            }}
                            value={(component.endAt && DateTime.fromISO(component.endAt)) || null}
                            inputFormat={"yyyy-MM-dd HH:mm"}
                            renderInput={(props) => (<TextField size={"small"} sx={{ width: "100%" }} {...props} />)}
                            label={"게시 종료"} />
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack direction={"row"} justifyContent="flex-end">
                      <div className="inlineWrapper">
                        <BoxVertical flex={1} paddingHorizontal={10}>
                          <BoxHorizontal alignItems="center">
                            {!!component.linkUrl && (
                              <EditableImage
                                url={!component.linkUrl ? NoImageIconSrc : component.linkUrl}
                                deleteImage={() => {
                                  setCardBenefitComponents((prev) =>
                                    prev.map((prevComponent) =>
                                      prevComponent.id === component.id ? {
                                        ...component,
                                        linkUrl: undefined,
                                      } : prevComponent,
                                    ),
                                  )
                                }}
                              />
                            )}
                            {!component.linkUrl && (
                              <Wrapper>
                                <Image src={NoImageIconSrc} alt="close icon" />
                              </Wrapper>
                            )}
                            <BoxVertical flex={1} justifyContent="center" paddingLeft={20}>
                              <span>랜딩 이미지</span>
                              <BoxHorizontal alignItems="center" paddingVertical={5}>
                                <UploadButton
                                  handleImage={(files) => {
                                    uploadImage(files, component)
                                  }}
                                  text={"이미지첨부"}
                                  variant={"outlined"}
                                />
                              </BoxHorizontal>
                            </BoxVertical>
                          </BoxHorizontal>
                        </BoxVertical>
                      </div>
                    </Stack>
                    <Stack direction={"row"} justifyContent="flex-end">
                      <Stack direction={"column"} justifyContent="center">
                        <Button
                          color={isNew(component.id) ? "success" : "primary"}
                          variant={"contained"}
                          size={"small"}
                          onClick={() => {
                            updateCardBenefit(component)
                          }}
                        >
                          {isNew(component.id) ? "등록" : "저장"}
                        </Button>
                        <Button
                          color={"error"}
                          variant={"contained"}
                          size={"small"}
                          onClick={() => {
                            deleteCardBenefit(component)
                          }}
                        >
                          삭제
                        </Button>
                      </Stack>
                    </Stack>

                  </Stack>)
              })}
            </Stack>
          </Grid>
        </Paper>
      </Grid>
    </Grid>

  )
}
