import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material"
import React, { useEffect, useReducer, useState } from "react"
import PreProductTable from "./PreProductTable"
import { PageType } from "types/PageType"
import { downloadPreProductsExcel, getSellOrderList, getSellOrders } from "../../apis/sellOrderAPI"
import { OrderType, SellOrderListSearchParams, SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { useLocation } from "react-router-dom"
import { DateTime } from "luxon"
import { CountType } from "types/CountType"
import Counters from "../SellOrder/Counters"
import { DesktopDatePicker } from "@mui/lab"
import { countProducts, launch } from "../../apis/productAPI"
import { toast } from "react-toastify"
import { toApplyCounts } from "../../utils/SellOrderTypeConverter"
import EditPreProductPopup from "./EditPreProductPopup"
import CreateSellOrderPopup from "../SellOrder/CreateSellOrderPopup"
import { DiagnosisResultCode } from "types/DiagnosisType"
import SellOrderExcelFileUploaderModal from "pages/PreProduct/SellOrderExcelFileUploaderModal"
import { isNil } from "utils/validationUtils"
import { numberWithCommas } from "../../utils/NumberUtils"
import { toDateTimeStr } from "../../utils/datetimeUtil"
import { downloadExcel } from "../../utils/excelUtil"

export interface PreProductState {
  selectedIds: string[]
  sellOrders: PageType<SellOrderType>
  productCount: CountType[]
  isShowEditPopup: boolean
  isCreatePopup: boolean
  editSellOrder?: SellOrderType
  startDateFilter?: DateTime
  endDateFilter?: DateTime
  searchFilterKey?: string
  searchFilterValue?: string
  completeSetupStatus?: string
  refresh: boolean
  loading: boolean
}

const initState: PreProductState = {
  selectedIds: [],
  sellOrders: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  productCount: [],
  isShowEditPopup: false,
  isCreatePopup: false,
  editSellOrder: undefined,
  startDateFilter: undefined,
  endDateFilter: undefined,
  searchFilterKey: "productId",
  searchFilterValue: undefined,
  completeSetupStatus: "ALL",
  refresh: false,
  loading: false,
}

interface PreProductAction {
  type: string
  payload?: any
}

function reducer(state: PreProductState, action: PreProductAction): PreProductState {
  switch (action.type) {
    case "SELECTION_PRODUCT_IDS":
      return { ...state, selectedIds: action.payload }
    case "FETCH_SELLORDER":
      return { ...state, sellOrders: action.payload }
    case "FETCH_PRODUCT_COUNT":
      return { ...state, productCount: action.payload }
    case "CHANGE_START_DATE":
      return { ...state, startDateFilter: action.payload }
    case "CHANGE_END_DATE":
      return { ...state, endDateFilter: action.payload }
    case "SHOW_CREATE_POPUP_OPEN":
      return { ...state, isCreatePopup: true }
    case "HIDE_CREATE_POPUP_OPEN":
      return { ...state, isCreatePopup: false }
    case "SHOW_EDIT_PRODUCT_POPUP":
      return { ...state, isShowEditPopup: true, editSellOrder: action.payload }
    case "HIDE_EDIT_PRODUCT_POPUP":
      return { ...state, isShowEditPopup: false, editSellOrder: undefined }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: "" }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
    case "CHANGE_COMPLETE_SETUP_STATUS":
      return { ...state, completeSetupStatus: action.payload  }
    case "SEARCH":
      return { ...state, refresh: !state.refresh }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }

  return state
}

const PreProducts = () => {
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, initState)
  const [saving, setSaving] = useState<boolean>(false)
  const [isModalOpen, setIsModelOpen] = useState<boolean>(false);

  const fetchSellOrders = (searchParams: any = {}) => {
    let newParams = {
      ...searchParams,
      diagnosisResults: [DiagnosisResultCode.합격, DiagnosisResultCode.조건부_합격],
      status: new URLSearchParams(location.search).get("status") || [
        SellOrderStatusCode.진단_완료,
        SellOrderStatusCode.판매_대기,
      ],
      sellOrderType: [OrderType.위탁, OrderType.직매입],
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
      completeSetupStatus: state.completeSetupStatus === 'ALL' ? null : state.completeSetupStatus
    }
    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    dispatch({ type: "START_LOADING" })
    getSellOrders(newParams)
      .then((sellOrders) => dispatch({ type: "FETCH_SELLORDER", payload: sellOrders }))
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  const fetchSellOrderCounts = (searchParams: any = {}) => {
    countProducts({
      ...searchParams,
      diagnosisResults: [DiagnosisResultCode.합격, DiagnosisResultCode.조건부_합격],
      sellOrderStatus: [SellOrderStatusCode.진단_완료, SellOrderStatusCode.판매_대기],
      sellOrderType: [OrderType.위탁, OrderType.직매입],
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
      completeSetupStatus: state.completeSetupStatus === 'ALL' ? null : state.completeSetupStatus
    }).then((result) => {
      dispatch({
        type: "FETCH_PRODUCT_COUNT",
        payload: toApplyCounts(result, [SellOrderStatusCode.진단_완료, SellOrderStatusCode.판매_대기], ".", [], true),
      })
    })
  }

  const handleNewSellOrderClick = () => {
    dispatch({ type: "SHOW_CREATE_POPUP_OPEN" })
  }
  const handleLaunchClick = () => {
    const selectedOrders = state.sellOrders.content.filter(
      (it) => state.selectedIds.includes(it.id!) && it.product?.completeSetupAt && !it.onSaleAt,
    )

    if (selectedOrders.length != state.selectedIds.length)
      toast.error("상품화가 완료된 판매 대기 상품만 노출이 가능합니다")
    else if (window.confirm(`${state.selectedIds.length} 건의 상품을 노출 설정하시겠습니까?`)) {
      setSaving(true)
      launch(state.selectedIds)
        .then((res) => {
          toast.success("상품 노출 처리 완료")
        })
        .catch((error) => {
          toast.error(`상품 노출 처리 실패 (${error.message || error})`)
        })
        .finally(() => {
          dispatch({ type: "REFRESH" })
          setSaving(false)
        })
    }
  }

  const handleExcelDownloadClick = (params: any = {}) => {

    if (isNil(state.startDateFilter)) {
      toast.error("시작일을 선택해주세요")
      return false
    }
    if (isNil(state.endDateFilter)) {
      toast.error("종료일을 선택해주세요")
      return false
    }
    // downloadPreProductsExcel({
    //   startDate: state.startDateFilter && state.startDateFilter.toISODate(),
    //   endDate: state.endDateFilter && state.endDateFilter.toISODate(),
    //   sellOrderTypes: [OrderType.위탁, OrderType.직매입],
    //   sellOrderStatus: [SellOrderStatusCode.진단_완료, SellOrderStatusCode.판매_대기],
    //   diagnosisResults: [DiagnosisResultCode.합격, DiagnosisResultCode.조건부_합격]
    // }).then((blob) => {
    //   const url = window.URL.createObjectURL(new Blob([blob]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', `상품관리_상품화.xlsx`);
    //   document.body.appendChild(link);
    //   link.click();
    // })
    const searchParams: any = {}
    let newParams = {
      ...searchParams,
      diagnosisResults: [DiagnosisResultCode.합격, DiagnosisResultCode.조건부_합격],
      status: new URLSearchParams(location.search).get("status") || [
        SellOrderStatusCode.진단_완료,
        SellOrderStatusCode.판매_대기,
      ],
      sellOrderType: [OrderType.위탁, OrderType.직매입],
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
      completeSetupStatus: state.completeSetupStatus === 'ALL' ? null : state.completeSetupStatus
    }
    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    getSellOrderList(newParams).then((orders) => {
      const data = orders.map((order) => {
        return {
          "상품 번호": order.product?.id,
          "판매주문 번호": order.id,
          "진행 상태": order.sellOrderStatus,
          "상품화" : order.product?.completeSetupAt ? "완료" : "미완료",
          "제품상태" : order.product?.exposedProductInfo?.productCondition ?? "",
          "판매방식" : order.product?.saleType  ?? "",
          "전시 판매": order.exhibitionSale ? '동의' :'미동의',
          "브랜드": order.product?.brand?.name,
          상품명: `${order.product?.title} ${order.product?.titleSecondary}`,
          "Ref No.": order.product?.detailModel.fullRefNo,
          "최적판매가": order.productDiagnosis?.viverPrices?.finalPrice,
          "희망판매가": order.productDiagnosis?.userDesiredPrice,
          "판매신청일": `${toDateTimeStr(order.acceptedAt) ?? ""}`,
          "안내완료일": `${toDateTimeStr(order.productDiagnosis?.notifiedAt) ?? ""}`,
          "고객승인완료일": `${toDateTimeStr(order.waitingSaleAt) ?? ""}`,
          "상품등록일": `${toDateTimeStr(order.onSaleAt) ?? ""}`
        }
      })

      downloadExcel(data, "상품관리_상품화")
    })
  }

  useEffect(() => {
    fetchSellOrders({
      page: state.sellOrders.pageable?.pageNumber || 0,
      size: state.sellOrders.pageable?.pageSize || 25,
    })
    fetchSellOrderCounts()
  }, [state.refresh, location.search])

  // const getProductsByOrderId = (orderIds: string[]) => {
  //     return state.sellOrders.content.filter(it => orderIds.includes(it.id!!))
  //         .map(it => it.productProduct!!)
  // }

  return (
    <Grid container spacing={2}>
      <CreateSellOrderPopup
        skipDiagnosis={true}
        open={state.isCreatePopup}
        handleClose={() => {
          dispatch({ type: "HIDE_CREATE_POPUP_OPEN" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <EditPreProductPopup
        open={state.isShowEditPopup}
        selectedSellOrderId={state.editSellOrder?.id}
        selectedProductId={state.editSellOrder?.product?.id}
        handleClose={() => {
          dispatch({ type: "HIDE_EDIT_PRODUCT_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
      />
      {
        <Grid item xs={12}>
          <Counters params={state.productCount} selectedLabel={new URLSearchParams(location.search).get("status")} />
        </Grid>
      }
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
            <InputLabel style={{ lineHeight: "45px", marginRight: "10px" }}>판매 신청일</InputLabel>
            <DesktopDatePicker
              label="시작일"
              inputFormat="yyyy-MM-dd"
              value={state.startDateFilter ?? null}
              onChange={(params) => {
                dispatch({ type: "CHANGE_START_DATE", payload: params })
                dispatch({ type: "REFRESH" })
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
            />
            <DesktopDatePicker
              label="종료일"
              inputFormat="yyyy-MM-dd"
              value={state.endDateFilter ?? null}
              onChange={(params) => {
                dispatch({ type: "CHANGE_END_DATE", payload: params })
                dispatch({ type: "REFRESH" })
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
            />
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end" spacing={0}>
            <FormControl sx={{ width: "160px" }} size={"small"}>
              <InputLabel id="search_key">상품화</InputLabel>
              <Select
                value={state.completeSetupStatus || ""}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_COMPLETE_SETUP_STATUS", payload: params.target.value })
                  dispatch({ type: "REFRESH" })
                }}
              >
                <MenuItem value={"ALL"}>전체</MenuItem>
                <MenuItem value={"Y"}>완료</MenuItem>
                <MenuItem value={"N"}>미완료</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "160px" }} size={"small"}>
              <InputLabel id="search_key">검색항목</InputLabel>
              <Select
                value={state.searchFilterKey || ""}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                }}
              >
                <MenuItem value={"id"}>판매주문번호</MenuItem>
                <MenuItem value={"productId"}>상품번호</MenuItem>
                <MenuItem value={"productTitle"}>상품명</MenuItem>
                <MenuItem value={"sellerName"}>판매자 이름</MenuItem>
                <MenuItem value={"sellerPhone"}>판매자 연락처</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "220px", marginBottom: "10px" }}
              size={"small"}
              value={state.searchFilterValue || ""}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch({ type: "SEARCH" })
                }
              }}
              onChange={(params) => {
                dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: params.target.value })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PreProductTable
              state={state}
              dispatch={dispatch}
              fetch={fetchSellOrders}
              components={[
                <Button
                  variant={"contained"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={handleExcelDownloadClick}
                >
                  엑셀 다운로드
                </Button>,
                <Button
                  variant={"contained"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={() => setIsModelOpen(true)}
                >
                  판매주문 대량등록
                </Button>,
                <Button
                  variant={"contained"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={handleNewSellOrderClick}
                >
                  판매주문 생성하기
                </Button>,
                <Button
                  variant={"contained"}
                  color={"error"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={handleLaunchClick}
                >
                  상품 노출
                </Button>,
              ]}
            />
          </Grid>
          <SellOrderExcelFileUploaderModal
            isOpen={isModalOpen}
            onCloseModal={() => setIsModelOpen(false)}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PreProducts
