import styled from "@emotion/styled"
import { PaginationParamsType } from "types/PageType"
import { PointStatusCode, PointType, PostPointBodyType } from "types/PointType"
import { deleteUserPoint, getPointHistoryList, postUserPoint } from "apis/pointAPI"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import PointListTable from "./PointListTable"
import AddPointModal from "./AddPointModal"
import Button from "components/Button"
import { toast } from "react-toastify"

const POINT_HISTORY_FETCH_SIZE = 20
const UserPointListPage = () => {
  const { userId = "" } = useParams()
  const [isAddPointModalOpen, setIsAddPointModalOpen] = useState(false)
  const [pointList, setPointList] = useState<PointType[]>([])
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: POINT_HISTORY_FETCH_SIZE,
  })

  const fetchPointList = useCallback(() => {
    getPointHistoryList(Number(userId), { size: POINT_HISTORY_FETCH_SIZE, page: paginationParams.number }).then(
      ({ content, totalElements = 0, totalPages = 0, first, size = POINT_HISTORY_FETCH_SIZE, number = 0 }) => {
        setPointList(content || [])
        setPaginationParams((prev) => ({ ...prev, totalElements, totalPages, first, size, number }))
      }
    )
  }, [userId, paginationParams.number])

  const changePage = useCallback((pageNumber: number) => {
    setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
  }, [])

  const addPoint = useCallback(
    (addPointBody: PostPointBodyType) => {
      postUserPoint(userId, addPointBody)
        .then((res) => {
          setPointList((prevList) => [res, ...prevList])
          setIsAddPointModalOpen(false)
        })
        .catch(() => toast.error("포인트 지급에 실패했습니다."))
    },
    [userId]
  )

  const cancelPoint = useCallback(
    (pointId: number) => {
      deleteUserPoint(userId, pointId)
        .then(() => {
          setPointList((prevList) => {
            return prevList.map((list) => (list.id === pointId ? { ...list, status: PointStatusCode.취소 } : list))
          })
        })
        .catch(() => toast.error("포인트 지급 취소에 실패했습니다."))
    },
    [userId]
  )

  useEffect(() => {
    fetchPointList()
  }, [fetchPointList])

  if (!userId) {
    return <></>
  }

  return (
    <>
      <PageHeaderWrapper>
        <ButtonWrapper>
          <Button buttonSize="L" onClick={() => setIsAddPointModalOpen(true)}>
            포인트 관리
          </Button>
        </ButtonWrapper>
      </PageHeaderWrapper>
      <PointListTable
        pointList={pointList}
        paginationParams={paginationParams}
        changePage={changePage}
        cancelPoint={cancelPoint}
      />
      <AddPointModal
        isOpen={isAddPointModalOpen}
        closeModal={() => setIsAddPointModalOpen(false)}
        addPoint={addPoint}
      />
    </>
  )
}

export default UserPointListPage

const PageHeaderWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 16px;
  margin-bottom: 20px;
  border-radius: 4px;
`

const ButtonWrapper = styled.div`
  width: 200px;
`
