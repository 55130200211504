import { DataGrid, GridColumnHeaderParams, GridToolbarContainer } from "@mui/x-data-grid"
import React from "react"
import { DateTime } from "luxon"
import { ProductState } from "./index"
import { truncateWithCommas } from "../../utils/NumberUtils"
import { toDateStr, toTimeStr } from "../../utils/datetimeUtil"
import { Button, Stack } from "@mui/material"
import { ProductStatusCode, ProductType } from "types/ProductType"
import { SellOrderType } from "../../types/SellOrderType"
import { BuyOrderType } from "../../types/BuyOrderType"

function createColumns(
  openQrPrintPopup: Function,
  openProductPopup: Function,
  openSellOrderPopup: Function,
  openBuyOrderPopup: Function
): any[] {
  const getColorByStatus = (status: ProductStatusCode) => {
    switch (status) {
      case ProductStatusCode.판매_대기:
      case ProductStatusCode.판매_취소:
        return "info"
      case ProductStatusCode.판매중:
        return "error"
      case ProductStatusCode.결제_대기:
      case ProductStatusCode.결제_완료:
        return "warning"
      case ProductStatusCode.판매_완료:
        return "success"
    }
  }

  return [
    {
      field: "id",
      headerName: "상품번호",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 90,
      renderHeader: (params: GridColumnHeaderParams) => <p style={{ lineHeight: 1 }}>상품 번호</p>,
      valueGetter: (params: any) => `${params.row.id}`,
      renderCell: (params: any) => (
        <div style={{ textAlign: "left", textDecoration: "underline" }} onClick={() => openQrPrintPopup(params.row)}>
          <span style={{ fontWeight: "bold" }}>{params.row.id}</span>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "상품상태",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      valueGetter: (params: any) => {
        return `${params.row.status}`
      },
      renderCell: (params: any) => (
        <div>
          <Button
            size={"small"}
            variant={"outlined"}
            style={{ fontWeight: "bold" }}
            onClick={() => {
              openProductPopup(params.row)
            }}
            color={getColorByStatus(params.row.status)}
          >
            {params.row.status}
          </Button>
        </div>
      ),
    },
    {
      field: "sellOrder",
      headerName: "판매주문",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      valueGetter: (params: any) => {
        return `${params.row.sellOrder.id}`
      },
      renderCell: (params: any) => (
        <div>
          {params.row.sellOrder?.id && (
            <Button
              size={"small"}
              variant={"outlined"}
              style={{ fontWeight: "bold" }}
              onClick={() => {
                openSellOrderPopup(params.row.sellOrder)
              }}
              color={getColorByStatus(params.row.sellOrder?.sellOrderStatus)}
            >
              {params.row.sellOrder?.sellOrderStatus}
            </Button>
          )}
        </div>
      ),
    },
    {
      field: "buyOrder",
      headerName: "구매주문",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      valueGetter: (params: any) => {
        return `${params.row.buyOrder.id}`
      },
      renderCell: (params: any) => (
        <div>
          {params.row.buyOrder?.id && (
            <Button
              size={"small"}
              variant={"outlined"}
              style={{ fontWeight: "bold" }}
              onClick={() => {
                openBuyOrderPopup(params.row.buyOrder)
              }}
              color={getColorByStatus(params.row.buyOrder?.buyOrderStatus)}
            >
              {params.row.buyOrder?.buyOrderStatus}
            </Button>
          )}
        </div>
      ),
    },
    {
      field: "onDisplay",
      headerName: "노출상태",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 80,
      maxWidth: 80,
      valueGetter: (params: any) => `${params.row.onDisplay ? "노출" : "숨김"}`,
      renderCell: (params: any) => (
        <div>
          <Button size={"small"} variant={"text"} color={params.row.onDisplay ? "success" : "error"}>
            {params.row.onDisplay ? "노출" : "숨김"}
          </Button>
        </div>
      ),
    },
    {
      field: "saleType",
      headerName: "판매방식",
      editable: false,
      sortable: false,
      align: "center",
      flex: 1,
      minWidth: 100,
      maxWidth: 140,
      valueGetter: (params: any) => `${params.row.saleType}`,
    },
    {
      field: "sellerId",
      headerName: "판매자 ID",
      editable: false,
      sortable: false,
      minWidth: 100,
      maxWidth: 140,
      flex: 1,
      valueGetter: (params: any) => `${params.row.sellerId}`,
    },
    {
      field: "brandName",
      headerName: "브랜드",
      editable: false,
      sortable: false,
      minWidth: 150,
      maxWidth: 200,
      flex: 1,
      valueGetter: (params: any) => `${params.row.brand.name}`,
    },
    {
      field: "title",
      headerName: "상품명",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 220,
      valueGetter: (params: any) => `${params.row.title}`,
      renderCell: (params: any) => (
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>{params.row.title}</span>
            <br />
            <span style={{ color: "#aaa" }}>{params.row.titleSecondary}</span>
          </p>
        </div>
      ),
    },
    {
      field: "fullRefNo",
      headerName: "Ref No.",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 160,
      maxWidth: 220,
      valueGetter: (params: any) => `${params.row.detailModel?.fullRefNo}`,
    },
    {
      field: "serialNo",
      headerName: "시리얼 No.",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 160,
      maxWidth: 220,
      valueGetter: (params: any) => `${params.row.serialNo ?? "-"}`,
    },
    {
      field: "price",
      headerName: "상점판매가",
      editable: false,
      sortable: false,
      align: "right",
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      valueGetter: (params: any) => {
        const price = params.row.price
        return price ? `${truncateWithCommas(price, 4)} 만원` : "-"
      },
    },
    {
      field: "acceptedAt",
      headerName: "판매신청일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          판매
          <br />
          신청일
        </p>
      ),
      valueGetter: (params: any) =>
        params.row.sellOrder?.acceptedAt
          ? DateTime.fromISO(params.row.sellOrder.acceptedAt).toFormat("yyyy-MM-dd HH:mm:ss")
          : "",
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.sellOrder?.acceptedAt, false)}
            <br />
            {toTimeStr(params.row.sellOrder?.acceptedAt)}
          </p>
        </div>
      ),
    },
    {
      field: "startSaleAt",
      headerName: "판매개시일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          판매
          <br />
          개시일
        </p>
      ),
      valueGetter: (params: any) =>
        params.value ? DateTime.fromISO(params.value)?.toFormat("yyyy-MM-dd HH:mm:ss") : "",
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.startSaleAt, false)}
            <br />
            {toTimeStr(params.row.startSaleAt)}
          </p>
        </div>
      ),
    },
    {
      field: "completeSaleAt",
      headerName: "판매완료일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: (params: GridColumnHeaderParams) => (
        <p style={{ lineHeight: 1 }}>
          판매
          <br />
          완료일
        </p>
      ),
      valueGetter: (params: any) =>
        params.value ? DateTime.fromISO(params.value)?.toFormat("yyyy-MM-dd HH:mm:ss") : "",
      renderCell: (params: any) => (
        <div style={{ textAlign: "center" }}>
          <p>
            {toDateStr(params.row.completeSaleAt, false)}
            <br />
            {toTimeStr(params.row.completeSaleAt)}
          </p>
        </div>
      ),
    },
  ]
}

type ProductProps = {
  state: ProductState
  dispatch: any
  fetch: Function
  components: any[]
}

const AllProductTable: React.FC<ProductProps> = ({ state, dispatch, fetch, components }) => {
  function CustomToolbar() {
    return <GridToolbarContainer style={{ justifyContent: "flex-end" }}>{components}</GridToolbarContainer>
  }

  const handleSelection = (ids: any[]) => {
    dispatch({ type: "SELECTION_PRODUCT_IDS", payload: ids as string[] })
  }

  const openQrPrintPopup = (product: ProductType) => {
    dispatch({ type: "TOGGLE_QR_PRINT_POPUP", payload: product })
  }
  const openProductPopup = (product?: ProductType) => {
    dispatch({ type: "TOGGLE_PRODUCT_POPUP", payload: product })
  }
  const openSellOrderPopup = (sellOrder?: SellOrderType) => {
    dispatch({ type: "TOGGLE_SELL_ORDER_POPUP", payload: sellOrder })
  }
  const openBuyOrderPopup = (buyOrder?: BuyOrderType) => {
    dispatch({ type: "TOGGLE_BUY_ORDER_POPUP", payload: buyOrder })
  }

  return (
    <>
      <Stack spacing={1}>
        <div style={{ height: 1200, width: "100%" }}>
          <DataGrid
            components={{ Toolbar: CustomToolbar }}
            getRowId={(row) => row.id!}
            columns={createColumns(openQrPrintPopup, openProductPopup, openSellOrderPopup, openBuyOrderPopup)}
            pagination
            {...dispatch.detailModels?.content}
            paginationMode="server"
            onPageChange={(page) => {
              fetch({ page, size: state.products.pageable?.pageSize || 25 })
            }}
            onPageSizeChange={(size) => {
              fetch({ page: 0, size })
            }}
            page={state.products.pageable?.pageNumber || 0}
            pageSize={state.products.pageable?.pageSize || 25}
            rowCount={state.products?.totalElements || 0}
            rows={state.products?.content || []}
            selectionModel={state.selectedIds}
            checkboxSelection
            disableSelectionOnClick={true}
            onSelectionModelChange={handleSelection}
            loading={state.loading}
          />
        </div>
      </Stack>
    </>
  )
}

export default AllProductTable
