import { Grid, Button, Stack, FormControl, InputLabel, Select, MenuItem, TextField, Paper } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { SettlementTable } from "./SettlementTable"
import { PageType } from "../../types/PageType"
import { SettlementType } from "../../types/SettlementType"
import { CountType } from "../../types/CountType"
import Counters from "../SellOrder/Counters"
import { useSearchParams } from "react-router-dom"
import { approveSettlementPayment, getSettlementList, SettlementListSearchParams } from "../../apis/settlementAPI"

export type SettlementState = {
  selectIds: string[]
  settlements:PageType<SettlementType>
  settlementCount:CountType[]
  searchFilterKey:'productId' | 'userId'
  searchFilterValue:string
  refresh:boolean;
  loading:boolean;
}

const initialState:SettlementState = {
  selectIds:[],
  settlements:{
    content:[],
    pageable:{pageSize:25, pageNumber:0},
    totalPages:0,
    totalElements:0,
    numberOfElements:0,
  },
  settlementCount: [],
  searchFilterKey: 'productId',
  searchFilterValue: '',
  refresh: false,
  loading: false
}

export const SettlementManager:React.FC = ()=>{
  const [state, setState] = useState<SettlementState>(initialState)
  const [currentSearchParams] = useSearchParams()


  const handleApprovePayment = useCallback(async ()=>{
    const requestApprovePayment = async (sellOrderId:string):Promise<'success'|{orderId:string, message:string}>=>{
      try{

        const result = await approveSettlementPayment(sellOrderId)
        setState((prevState)=>({
          ...prevState,
          settlements:{
            ...prevState.settlements,
            content:prevState.settlements.content.map((item)=>({
              ...item,
              ...result
            }))
          }
        }))
        return 'success'
      } catch(ex){
        return {
          orderId:sellOrderId,
          message:(ex as Error).message
        }
      }

    }

    const results = await Promise.all(state.selectIds.map((sellOrderId)=>requestApprovePayment(sellOrderId)))
    const successItem = results.filter((status)=> status === 'success')
    const failedItem = results.filter((status)=> status !== 'success');
    let resultMessage = `지급 승인 결과\n\n[성공 : ${successItem.length}건, 실패 ${failedItem.length}건]`;

    if(failedItem.length > 0){
      const failedItemMessageList = failedItem.map((item)=>{
        if(item === 'success') return '';
        return `주문번호 : ${item.orderId}, 에러내용 : ${item.message}`
      }).join('\n')
      resultMessage = `${resultMessage}\n\n======= 실패 사유 =======\n${failedItemMessageList}\n====================`;
    }
    alert(resultMessage)
  }, [state.selectIds, state.settlements.content])

  const fetchSettlementList = (searchParams:SettlementListSearchParams = {})=>{
    getSettlementList(searchParams).then((settlementItem)=>{
      setState((prevState)=>({
        ...prevState,
        settlements:settlementItem
      }))
    })
  }

  useEffect(()=>{
    fetchSettlementList({
      page: state.settlements.pageable?.pageNumber ?? 0,
      size: state.settlements.pageable?.pageSize ?? 25
    })
  }, [state.refresh, currentSearchParams])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <Stack spacing={1} direction={"column"}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="search_key">검색항목</InputLabel>
                    <Select<'productId'|'userId'>
                      value={state.searchFilterKey || ""}
                      onChange={(params) => {
                        setState((prevState)=>({
                          ...prevState,
                          searchFilterKey:params.target.value as 'productId'|'userId',
                          searchFilterValue:'',
                        }))
                      }}
                    >
                      <MenuItem value={"productId"}>상품번호</MenuItem>
                      <MenuItem value={"userId"}>판매자 ID</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: "220px" }}
                    size={"small"}
                    value={state.searchFilterValue || ""}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setState((prevState)=>({
                          ...prevState,
                          refresh:!prevState.refresh
                        }))
                      }
                    }}
                    onChange={(params) => {
                      setState((prevState)=>({
                       ...prevState,
                       searchFilterValue:params.target.value
                      }))
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <SettlementTable
              state={state}
              dispatch={setState}
              components={[
              ]}
              fetch={fetchSettlementList}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
