import React, { Dispatch, ReactElement, SetStateAction } from "react"
import { Button, Grid } from "@mui/material"
import { SettlementType } from "../../types/SettlementType"
import { DataGrid, GridColDef, GridColumnHeaderParams, GridToolbarContainer } from "@mui/x-data-grid"
import { numberWithCommas, truncate } from "../../utils/NumberUtils"
import { toDateTimeStr } from "../../utils/datetimeUtil"
import { SettlementState } from "./SettlementManager"


function createColumns(): GridColDef[] {
  const getColorByStatus = (status: '대기'|'승인') => {
    switch (status) {
      case '대기':
        return 'error';
      case '승인':
        return 'success'
    }
  }

  return [
    {
      field: 'id',
      headerName: "상품번호",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 130,
      maxWidth: 160,
      renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>상품번호 <br/> (판매주문번호)</p>),
      valueGetter: (params: { row:SettlementType }) => `${params.row.product.id}`,
      renderCell: (params: { row:SettlementType }) => (
        <div style={{textAlign: "left"}}>
          <p><span style={{fontWeight: "bold"}}>{params.row.product.id}</span>
            <br/><span style={{color: '#aaa'}}>{params.row.orderId}</span></p>
        </div>
      )
    },
    {field: 'status', headerName: "진행상태", editable: false, sortable: false,
      align: 'center',
      flex: 1,
      minWidth: 90,
      maxWidth: 120,
      valueGetter: (params: { row: SettlementType }) => {
        return `${params.row.confirmStatus}`
      },
      renderCell: (params: { row:SettlementType }) => (
        <div>
          <Button
            size={"small"}
            variant={"outlined"}
            style={{fontWeight: "bold"}}
            color={getColorByStatus(params.row.confirmStatus)}>
            {params.row.confirmStatus === '승인' ? '정산 완료':'정산 대기'}
          </Button>
        </div>
      )
    },
    {field: 'title', headerName: "상품명", editable: false, sortable: false,
      minWidth: 220,
      flex: 1,
      valueGetter: (params: { row:SettlementType }) => `${params.row.product}`,
      renderCell: (params: { row:SettlementType }) => (
        <div>
          <p>{params.row.product.title}<br/>
            {params.row.product.titleSecondary}</p>
        </div>
      )
    },
    {field: 'productPrice', headerName: "구매가", editable: false, sortable: false,
      align: 'right',
      flex: 1,
      minWidth: 100,
      maxWidth: 120,
      valueGetter: (params: { row:SettlementType }) => {
        const price = params.row.productPrice
        return price ? `${numberWithCommas(truncate(price, 4))} 만원` : '-'
      }
    },

    {field: 'upgradePrice', headerName: "서비스 이용료", editable: false, sortable: false,
      align: 'right',
      flex: 1,
      minWidth: 100,
      maxWidth: 120,
      valueGetter: (params: { row:SettlementType }) => {
        const price = params.row.feeAmount
        return price ? `${numberWithCommas(truncate(price, 4))} 만원` : '-'
      }
    },

    {field: 'settlementPrice', headerName: "정산 금액", editable: false, sortable: false,
      align: 'right',
      flex: 1,
      minWidth: 110,
      maxWidth: 150,
      valueGetter: (params: { row:SettlementType }) => {
        const price = params.row.amount
        return price ? `${numberWithCommas(truncate(price, 4))} 만원` : '-'
      }
    },
    {
      field: 'sellerUserId',
      headerName: "판매자 ID",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      valueGetter: (params: { row:SettlementType }) => `${params.row.userId}`,
    },
    {
      field: 'paymentRequestDate',
      headerName: "지급 요청일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 180,
      maxWidth: 200,
      valueGetter: (params: { row:SettlementType }) => toDateTimeStr(params.row.payoutDate),
    },
    {
      field: 'paymentApproveDate',
      headerName: "지급 승인일",
      editable: false,
      sortable: false,
      flex: 1,
      minWidth: 180,
      maxWidth: 200,
      valueGetter: (params: { row:SettlementType }) => params.row.confirmedAt !== null  ? toDateTimeStr(params.row.confirmedAt) : '-',
    },
  ]
}


type SettlementTableProps = {
  state: SettlementState,
  dispatch: Dispatch<SetStateAction<SettlementState>>,
  fetch: (params:{page:number, size:number})=>void,
  components: ReactElement[],
}


export const SettlementTable:React.FC<SettlementTableProps> = (props)=>{
  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{justifyContent: "flex-end"}}>
        {props.components}
      </GridToolbarContainer>
    );
  }

  const handleSelection = (ids:(string|number)[])=>{
    console.log(ids)
    props.dispatch((prevState)=>({
      ...prevState,
      selectIds:ids.map((id)=> {
        if(typeof id === "number") return id.toString()

        return id;
      })
    }))
  }


  return (
    <Grid container spacing={1} sx={{pt: 1}}>
      <Grid item xs={12}>
        <div style={{height: 1200, width: '100%'}}>
          <DataGrid<SettlementType>
            components={{Toolbar: CustomToolbar}}
            getRowId={row => row.orderId!}
            columns={createColumns()}

            pagination
            paginationMode="server"
            onPageChange={(page) => {
              props.fetch({page, size: props.state.settlements.pageable?.pageSize || 25})
            }}
            onPageSizeChange={(size) => {
              props.fetch({page: 0, size})
            }}

            page={props.state.settlements.pageable?.pageNumber ?? 0}
            pageSize={props.state.settlements.pageable?.pageSize ?? 25}
            rowCount={props.state.settlements.totalElements ?? 0}
            rows={props.state.settlements.content ?? []}

            selectionModel={props.state.selectIds}
            checkboxSelection
            disableSelectionOnClick={true}
            onSelectionModelChange={handleSelection}
            loading={props.state.loading}
          />

        </div>
      </Grid>
    </Grid>

  );

}
