import { SaleTypeCode } from "./ProductType"
import { PaymentMethodTypeCode } from "./PaymentType"

export declare interface PaymentAreaComponentType {
  id: number
  saleType?: SaleTypeCode
  componentType: PaymentAreaComponentTypeCode
  priority?: number
  onDisplay?: boolean

  messageLevel?: PaymentAreaMessageLevelCode
  title?: string
  description?: string
  linkUrl?: string
  bannerImageUrl?: string

  paymentMethodType?: PaymentAreaPaymentComponentTypeCode
  titleTags?: string[]

  startAt?: String
  endAt?: String
}

export const enum PaymentAreaComponentTypeCode {
  메시지 = "메시지",
  결제수단 = "결제수단",
  배너 = "배너",
  즉시할인 = "즉시할인",
  카드사혜택 = "카드사혜택",
}

export const enum PaymentAreaMessageLevelCode {
  공지사항 = "공지사항",
  프로모션 = "프로모션",
}

export const enum PaymentAreaPaymentComponentTypeCode {
  바이버페이 = "바이버페이",
  신용카드 = "신용카드",
  가상계좌 = "가상계좌",
  카카오페이 = "카카오페이",
  분할결제 = "분할결제",
  결제위젯 = "결제위젯"
}

export function PaymentMethodEditable(paymentAreaPaymentComponentType: PaymentAreaPaymentComponentTypeCode | undefined){
  const allowedPaymentTypes = [
    PaymentAreaPaymentComponentTypeCode.바이버페이,
    PaymentAreaPaymentComponentTypeCode.가상계좌,
    PaymentAreaPaymentComponentTypeCode.분할결제,
    PaymentAreaPaymentComponentTypeCode.결제위젯
  ];

  const isAllowedPaymentType = allowedPaymentTypes.includes(paymentAreaPaymentComponentType!!);

  if (isAllowedPaymentType) {
    return true;
  } else {
    return false;
  }
}

export interface PaymentMessageComponentType extends PaymentAreaComponentType {
  id: number
  saleType?: SaleTypeCode
  componentType: PaymentAreaComponentTypeCode.메시지
  priority?: number
  onDisplay?: boolean
  messageLevel?: PaymentAreaMessageLevelCode
  title?: string
  description?: string
  startAt?: string
  endAt?: string
}

export interface PaymentMethodComponentType extends PaymentAreaComponentType {
  id: number
  saleType?: SaleTypeCode
  componentType: PaymentAreaComponentTypeCode.결제수단
  priority?: number
  onDisplay?: boolean
  description?: string
  paymentMethodType?: PaymentAreaPaymentComponentTypeCode
  titleTags?: string[]
  paymentMethodEditable?: boolean
}

export interface PaymentDiscountNoticeComponentType extends PaymentAreaComponentType {
  id: number
  saleType?: SaleTypeCode
  componentType: PaymentAreaComponentTypeCode.즉시할인
  priority?: number
  onDisplay?: boolean
  description?: string
  startAt?: string
  endAt?: string
}

export interface PaymentCardBenefitComponentType extends PaymentAreaComponentType {
  id: number
  saleType?: SaleTypeCode
  componentType: PaymentAreaComponentTypeCode.카드사혜택
  priority?: number
  onDisplay?: boolean
  title?: string
  description?: string
  linkUrl?: string
  startAt?: string
  endAt?: string
}

export interface PaymentBannerComponentType extends PaymentAreaComponentType {
  id: number
  saleType?: SaleTypeCode
  componentType: PaymentAreaComponentTypeCode.배너
  priority?: number
  onDisplay?: boolean
  linkUrl?: string
  bannerImageUrl?: string
  startAt?: string
  endAt?: string
}
