import { MenuItem, Select } from "@mui/material"
import { FaqCategoryType } from "types/FaqType"

const faqCategoryMenuList = [
  {
    value: "공통",
    title: "공통",
  },
  {
    value: "판매",
    title: "판매",
  },
  {
    value: "구매",
    title: "구매",
  },
  {
    value: "배송",
    title: "배송",
  },
  {
    value: "바이버 랩스",
    title: "바이버 랩스",
  },
  {
    value: "서비스 이용",
    title: "서비스 이용",
  },
  {
    value: "바이버 쇼룸",
    title: "바이버 쇼룸",
  },
]

type Props = {
  selectedCategory: FaqCategoryType
  selectCategory: (category: FaqCategoryType) => void
}

export const FaqCategorySelect = ({ selectedCategory, selectCategory }: Props) => {
  return (
    <Select
      size={"small"}
      style={{
        minWidth: "120px",
        height: "36px",
      }}
      required
      value={selectedCategory}
      onChange={(e) => {
        const value = e.target.value as FaqCategoryType
        selectCategory(value)
      }}
    >
      {faqCategoryMenuList.map((menu) => (
        <MenuItem value={menu.value} key={menu.title}>
          {menu.title}
        </MenuItem>
      ))}
    </Select>
  )
}
