import { httpDelete, httpGet, httpPost, httpPostMultipartFile } from "utils/httpAPI"
import { PageType } from "types/PageType"
import {
  SuperLoginHistory,
  UserBuyOrderType,
  UserDetailType,
  UserListSearchParams,
  UserMemoType,
  UserSellOrderType,
  UserType,
} from "types/UserType"

export const findUserByCsv: (csvFile: FileList) => Promise<UserType[]> = (csvFile) =>
  httpPostMultipartFile("/users/find-by-csv", csvFile)

export const findUserByPhoneNumber: (phoneNumberList: string[]) => Promise<UserType[]> = (phoneNumberList) =>
  httpPost("/users/find-by-phone", { phones: phoneNumberList })

export const getUserList: (searchParams: UserListSearchParams) => Promise<PageType<UserType>> = (searchParams) =>
  httpGet("/users", searchParams)

export const getUserDetail: (userId: string, unmasking?: boolean) => Promise<UserDetailType> = (userId, unmasking) =>
  httpGet(`/users/${userId}`, {unmasking: unmasking})

export const getUserSellOrderList: (userId: string) => Promise<UserSellOrderType[]> = (userId: string) =>
  httpGet(`/users/${userId}/sell-orders`)

export const getUserBuyOrderList: (userId: string) => Promise<UserBuyOrderType[]> = (userId: string) =>
  httpGet(`/users/${userId}/buy-orders`)

export const postUserNickname: (userId: string, changedNickname: string) => Promise<UserType> = (
  userId: string,
  changedNickname: string
) => httpPost(`/users/${userId}/nickname`, { nickname: changedNickname })

export const postUserPhoneNumber: (userId: string, changedPhoneNumber: string) => Promise<UserType> = (
  userId: string,
  changedPhoneNumber: string
) => httpPost(`/users/${userId}/phone`, { phone: changedPhoneNumber })

export const postUserMemo: (userId: string, memo: string) => Promise<UserMemoType> = (userId: string, memo: string) =>
  httpPost(`/users/${userId}/memos`, { memo })

export const deleteUserMemo: (userId: string, memoId: number) => Promise<UserMemoType> = (
  userId: string,
  memoId: number
) => httpDelete(`/users/${userId}/memos/${memoId}`)

export const getSuperLoginHistory: (userId: number) => Promise<SuperLoginHistory[]> = (
  userId: number
) => httpGet(`/users/${userId}/super-login`)

export const postSuperLogin: (userId: number, superLoginReason: string) => Promise<string> = (
  userId: number,
  superLoginReason: string
) => httpPost(`/users/${userId}/super-login`, {reason: superLoginReason})
