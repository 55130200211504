import styled from "@emotion/styled"
import { Autocomplete, TextField } from "@mui/material"
import { useState } from "react"
import { SerialNumberListItemType } from "types/SerialNumberType"
import { isNotNil } from "utils/validationUtils"
import { SerialNumberDetailModal } from "../SerialNumberDetailModal"
import { useSerialNumberFinder } from "./useSerialNumberFinder"

const warningIconSrc = require("images/warning_icon.png")
const paperIconSrc = require("images/paper_icon.png")

type Props = {
  value: string
  onChangeValue: (value: string) => void
  onSelect: (serialNumber: SerialNumberListItemType | null) => void
}

export const SerialNumberFinder = ({ value, onSelect, onChangeValue }: Props) => {
  const [isOpenSerialDetailModal, setIsOpenSerialDetailModal] = useState<boolean>(false)
  const { selectedSerial, setSelectedSerial, serialList, fetchSerialList } = useSerialNumberFinder(value)

  return (
    <>
      {isOpenSerialDetailModal && selectedSerial && (
        <SerialNumberDetailModal
          serialNumber={selectedSerial.serialNo}
          closeModal={() => setIsOpenSerialDetailModal(false)}
        />
      )}
      <Wrapper>
        <Autocomplete<SerialNumberListItemType>
          disablePortal
          options={serialList}
          getOptionLabel={(option) => option.serialNo}
          fullWidth
          onFocus={fetchSerialList}
          clearOnBlur={false}
          clearIcon={null}
          popupIcon={null}
          renderInput={(params) => (
            <InlineWrapper>
              <TextField {...params} variant="standard" type={"text"} required />
              {isNotNil(selectedSerial) && (
                <IconWrapper>
                  {selectedSerial.blacklist && (
                    <Icon src={warningIconSrc} style={{ width: "14px", height: "14px", marginRight: "8px" }} />
                  )}
                  <Icon
                    src={paperIconSrc}
                    style={{ width: "20px", height: "20px" }}
                    onClick={() => setIsOpenSerialDetailModal(true)}
                  />
                </IconWrapper>
              )}
            </InlineWrapper>
          )}
          inputValue={value}
          onInputChange={(e, value) => {
            if (e) onChangeValue(value)
          }}
          onChange={(e, option) => {
            setSelectedSerial(option)
            onSelect(option)
          }}
          renderOption={(props, option) => (
            <MenuItem {...props}>
              <Label>{option.serialNo}</Label>
              <IconWrapper>
                {option.blacklist && (
                  <Icon src={warningIconSrc} style={{ width: "14px", height: "14px", marginRight: "8px" }} />
                )}
                <Icon src={paperIconSrc} style={{ width: "20px", height: "20px" }} />
              </IconWrapper>
            </MenuItem>
          )}
        />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  flex: 1;
`

const MenuItem = styled.li`
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px;

  :hover {
    background: #eeeeee;
  }
`

const Label = styled.div`
  width: 85%;
  text-align: left;
`

const Icon = styled.img``

const IconWrapper = styled.div`
  width: 15%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
`

const InlineWrapper = styled.div`
  width: 100%;
  display: inline-flex;
`
