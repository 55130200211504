import {BuyOrderStatusCode, BuyOrderType} from "types/BuyOrderType"
import {ShippingType} from "types/ShippingType"

export const getShippings = (buyOrderList: BuyOrderType[], selectedIdList: string[]): ShippingType[] => {
  return buyOrderList
    .filter(
      (buyOrder) =>
        selectedIdList.includes(buyOrder.id!) && (buyOrder.buyOrderStatus === BuyOrderStatusCode.배송_준비 || buyOrder.buyOrderStatus === BuyOrderStatusCode.배송중)
    )
    .map((buyOrder) => {
      return {
        ...buyOrder.shipping!,
        orderId: buyOrder?.id,
        productId: buyOrder?.product?.id,
        productTitle: buyOrder?.product?.title,
        quantity: 1,
        weight: 1,
        price: buyOrder?.product?.price,
      }
    })
}

export const getSelectedOrders = (buyOrderList: BuyOrderType[], selectedIdList: string[]): BuyOrderType[] => {
  return buyOrderList.filter((buyOrder) => selectedIdList.includes(buyOrder?.id || ""))
}
