import { getProduct, updateProduct } from "apis/productAPI"

import { toast } from "react-toastify"
import { ResellPriceResponse } from "types/EstimationType"
import { useEffect, useState } from "react"
import { CurrencyCode, ProductStatusCode, ProductType, SaleTypeCode } from "types/ProductType"
import { getCancelSellOrderPenaltyFee, getResellPrice } from "apis/estimationAPI"
import { updateSellOrder } from "apis/sellOrderAPI"
import { getBrand } from "../../apis/brandAPI"
import { BrandType } from "../../types/BrandType"

type PreProductType = Omit<ProductType, "id"> & {
  id?: number
}

const initialProductData: PreProductType = {
  upgradeRequired: [],
  images: [],
  status: ProductStatusCode.결제_대기,
  currency: CurrencyCode.KR,
  saleType: SaleTypeCode.위탁판매,
  detailModel: {},
}

type Props = {
  productId?: number
  handleClose: Function
}

export const useBeforeDiagnosisProductData = ({ productId, handleClose }: Props) => {
  const [brand, setBrand] = useState<BrandType | undefined>()
  const [product, setProduct] = useState<PreProductType>(initialProductData)
  const [productPrice, setProductPrice] = useState<number>(0)
  const [penalty, setPenalty] = useState<number>(0)
  const [estimatedPrice, setEstimatedPrice] = useState<ResellPriceResponse>({})

  const [editing, setEditing] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)

  // 상품 정보 저장
  const update = async (updatedProduct: PreProductType) => {
    if (!updatedProduct?.id) return

    if (updatedProduct.status !== ProductStatusCode.판매_대기 && updatedProduct.status !== ProductStatusCode.판매중) {
      toast.error("판매가 완료된 상품은 수정할 수 없습니다")
      return
    }

    const confirmMessage =
      "경고:\n판매중 상품의 정보를 수정하면\n수정 전 정보를 바탕으로 구매를 진행한 고객의\n컴플레인의 대상이 될 수 있습니다."
    if (!window.confirm(confirmMessage)) {
      return
    }

    try {
      setSaving(true)
      await updateProduct(updatedProduct.id, updatedProduct)
      toast.success("저장 완료")
    } catch (error: any) {
      toast.error(`저장 실패 (${error.message || error})`)
    } finally {
      setSaving(false)
    }
  }

  // 판매가 변경
  const changeProductPrice = async () => {
    if (product.status !== ProductStatusCode.판매_대기 && product.status !== ProductStatusCode.판매중) {
      toast.error("판매가 완료된 상품은 수정할 수 없습니다")
      return
    }

    const confirmMessage =
      "경고:\n판매중 상품의 정보를 수정하면\n수정 전 정보를 바탕으로 구매를 진행한 고객의\n컴플레인의 대상이 될 수 있습니다."
    if (!window.confirm(confirmMessage)) {
      return
    }

    if (product.saleType === SaleTypeCode.위탁판매) {
      const confirmMessageBeforeEditingEntrustedProductPrice =
        "경고:\n위탁판매 상품의 판매가 변경은 반드시 고객의 사전동의를 받아야하며\n동의없이 이루어진 판매가 변경으로 발생한 피해는\n온전히 변경한 사람 본인이 책임지는 것에 동의합니다."
      if (!window.confirm(confirmMessageBeforeEditingEntrustedProductPrice)) {
        setEditing(false)
        return
      }
    }

    try {
      await updateSellOrder(product?.sellOrder?.id, { price: parseInt(productPrice.toString()) }).then(() => {})
      setProduct((prevProduct) => ({ ...prevProduct, price: parseInt(productPrice.toString()) }))
      toast.success("판매가 변경 성공")
    } catch {
      toast.error("판매가 변경 실패")
    } finally {
      setEditing(false)
    }
  }

  // 현재 상태에 대한 예상파매가 조회
  const getCurrentResellPrice = (product: PreProductType) => {
    const dto = {
      detailModel: { id: product?.detailModel?.id! },
      mainProductCondition: product.exposedProductInfo?.productCondition,
      accessoriesCondition: product.exposedProductInfo?.accessoriesCondition,
      officialGuarantee: product.exposedProductInfo?.officialGuarantee,
      stampingYear: product.exposedProductInfo?.stampingDate
        ? parseInt(product.exposedProductInfo?.stampingDate.substring(0, 4))
        : undefined,
      originalBoxCondition: product.exposedProductInfo?.originalBoxCondition,
    }
    getResellPrice(dto).then((res) => {
      setEstimatedPrice(res)
    })
  }

  useEffect(() => {
    if (!productId) {
      return
    }

    getProduct(productId).then((product) => {
      setProduct(product)
      setProductPrice(product?.price || 0)
      getCurrentResellPrice(product)
      getBrand(product?.brandSid || "ETC").then((brand) => {
        setBrand(brand)
      })
    })

    if (product?.sellOrder?.id) {
      getCancelSellOrderPenaltyFee({ sellOrder: { id: product?.sellOrder?.id } }).then((penalty) => {
        setPenalty(penalty.paymentAmount)
      })
    }
  }, [productId, product?.sellOrder?.id])

  const updateOnDisplay = async (onDisplay: boolean) => {
    if (!productId) {
      return
    }

    try {
      setSaving(true)
      await updateProduct(productId, { onDisplay })
      toast.success(onDisplay ? "노출중으로 설정 완료" : "숨김 설정 완료")
    } catch (error: any) {
      toast.error(`변경 실패 (${error.message || error})`)
    } finally {
      setSaving(false)
    }
  }

  return {
    brand,
    product,
    setProduct,
    changeProductPrice,
    update,
    saving,
    estimatedPrice,
    penalty,
    getCurrentResellPrice,
    editing,
    setEditing,
    setProductPrice,
    productPrice,
    updateOnDisplay,
  }
}
