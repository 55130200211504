import { OrderType, SellOrderStatusCode } from "./SellOrderType"
import { JourneyType, ShippingStatusCode, ShippingType, ShippingTypeCode } from "./ShippingType"
import { UserType } from "./UserType"
import { DateTime } from "luxon"

export type PenaltyDetail = {
  orderId: string // 판매 주문 번호
  type: PenaltyTypeCode // 유형 [구매취소, 판매포기, 판매거절]
  status: PenaltyStatusCode // 진행 상태 [완료, 실패, 대기중]
  paymentAmount: number // 청구 금액
  completedAt?: string // 페널티 완납일시
  createdAt: string // 페널티 발생일시
  sellOrder: {
    id: string
    orderedAt: string // 판매 신청일시
    sellOrderType: OrderType // 판매 방식
    seller: UserType
    sellOrderStatus: SellOrderStatusCode
    product: {
      id: number // 상품 번호
      title: string // 상품명
      titleSecondary: string //상품명2
      initialPrice: number // 페널티 기준가
      brand: {
        sid: string
        name: string
      }
      detailModel: {
        id: number
        refNo: string
        fullRefNo: string // Ref No
      }
    }
    shipping: ShippingType
    cancelShipping?: {
      id: number
      shippingType: ShippingTypeCode
      name: string // 이름
      phone: string // 연락처
      address: string // 주소
      shippingStatus: ShippingStatusCode // 배송상태
      trackingNo: string // 운송송장번호
      journey: JourneyType[]
    }
  }
  breakdown: {
    fees: PenaltyFeeType[]
    paymentAmount: number
  }
  description: string // 상품 상태 (포맷 변경 예정)
  refundAmount: number | null // 환불 금액
  refundReason: string | null // 환불 사유
  refundedAt: string | null // 환불 일시
}

export type CancelShippingCode = ShippingStatusCode | "반송 대기"

export type PenaltyListItem = {
  orderId: string
  type: PenaltyTypeCode
  status: PenaltyStatusCode
  paymentAmount: number
  refundAmount?: number | null
  completedAt?: string
  createdAt: string
  sellOrder: {
    id: string
    seller: UserType
    sellOrderStatus: SellOrderStatusCode
    product: {
      id: number
      title: string
    }
    shipping: ShippingType
    cancelShipping: {
      id?: number
      shippingStatus?: ShippingStatusCode
      shippingType?: ShippingTypeCode
    }
    orderedAt: string
  }
}

export enum PenaltyStatusCode {
  완료 = "완료",
  실패 = "실패",
  대기중 = "대기중",
}

export enum PenaltyTypeCode {
  구매취소 = "구매취소",
  판매포기 = "판매포기",
  판매거절 = "판매거절",
}

export type PenaltyListSearchParams = {
  size?: number
  page?: number
  sellerId?: string,
  sellerName?: string,
  startDate?: string,
  endDate?: string,
  cancelShippingStatus?: ShippingStatusCode
  cancelShippingIsNull?: boolean,
  includeRequiredManagement?: boolean // 관리가 필요한 페널티만 조회 (페널티 결제 금액이 있거나, 반송 배송이 있는 주문)
}

export type PenaltySearchFilterParams = {
  sellerId?: string
  sellerName?: string
}

export type PenaltySearchDateFilter = {
  startDate?: DateTime | null
  endDate?: DateTime | null
}

export type PenaltyFeeType = {
  feePolicyType: "판매 업그레이드 수수료" | "판매 취소 페널티"
  calculationType: "정률" | "정가"
  rate?: number // 비율
  calculationAmount?: number
  discountAmount?: number
  amount: number // 페널티 금액
}
