import styled from "@emotion/styled"

type CalloutProps = {
  type: 'info' | 'warning' | 'error';
  title: string,
  children: React.ReactNode;
};

const getEmoji = (type: 'info' | 'warning' | 'error'): string => {
  switch (type) {
    case 'info':
      return 'ℹ️';
    case 'warning':
      return '⚠️';
    case 'error':
      return '❌';
    default:
      return '';
  }
};


const Callout: React.FC<CalloutProps> = ({ type, title, children }) => {
  const emoji = getEmoji(type);
  return (
    <StyledCallout type={type}>
      <Title>{emoji} {title}</Title>
      <div>{children}</div>
    </StyledCallout>
  );
}

export default Callout

const StyledCallout = styled.div<Pick<CalloutProps, 'type'>>`
    padding: 20px 20px;
    margin: 10px 0;
    border-radius: 4px;
    color: ${(props) =>
            props.type === "info"
                    ? "#31708f"
                    : props.type === "warning"
                            ? "#8a6d3b"
                            : props.type === "error"
                                    ? "#a94442"
                                    : "#000"};
    background-color: ${(props) =>
            props.type === "info"
                    ? "#d9edf7"
                    : props.type === "warning"
                            ? "#fcf8e3"
                            : props.type === "error"
                                    ? "#f2dede"
                                    : "#fff"};
    border-color: ${(props) =>
            props.type === "info"
                    ? "#bce8f1"
                    : props.type === "warning"
                            ? "#faebcc"
                            : props.type === "error"
                                    ? "#ebccd1"
                                    : "#eee"};
`

const Title = styled.h4`
  margin: 0 0 10px 0;
`;
