export const enum PaymentMethodTypeCode {
    신용카드 = '신용카드',
    가상계좌 = '가상계좌',
    카카오페이 = '카카오페이',
    토스 = '토스',
    네이버페이 = '네이버페이',
    계좌이체 = '계좌이체',
    모바일 = '모바일',
    도서상품권 = '도서상품권',
    문화상품권 = '문화상품권',
    스마트문화상품권 = '스마트문화상품권',
    해피머니 = '해피머니',
    모바일팝 = '모바일팝',
    틴캐시 = '틴캐시',
    티머니 = '티머니',
    편의점캐시 = '편의점캐시',
    에그머니 = '에그머니',
    온캐시 = '온캐시',
    폰빌 = '폰빌',
    캐시비 = '캐시비',
    페이코 = '페이코',
    체크페이 = '체크페이',
    SSG페이 = 'SSG페이',
    LPAY = 'LPAY',
    삼성페이 = '삼성페이',
    차이 = '차이',
    현금 = '현금',
    알리페이 = '알리페이',
    제로페이 = '제로페이',
    서울옥션 = '서울옥션',
    서울옥션_경매 = '서울옥션_경매'
}
export const enum PaymentChannelTypeCode {
    PG = 'PG',
    바이버페이 = '바이버페이',
    현장결제 = '현장결제'
}
export const enum PaymentStatusCode {
    결제_요청_대기 = '결제 요청 대기',
    결제_요청 = '결제 요청',
    결제_요청_취소 = '결제 요청 취소',
    결제_요청_만료 = '결제 요청 만료',
    결제_성공 = '결제 성공',
    결제_실패 = '결제 실패',
    결제_취소_대기 = '결제 취소 대기',
    결제_취소_성공 = '결제 취소 성공',
    결제_취소_실패 = '결제 취소 실패',
}
export const enum PaymentTypeCode {
    구매  = '구매 ',
    판매_포기_페널티 = '판매 포기 페널티',
    구매_환불_배송료 = '구매 환불 배송료',
}


export declare interface PaymentType {
    id?: number,
    orderId?: string,
    userId?: number,
    userName?: string,
    email?: string,
    channelType?: PaymentChannelTypeCode,
    type?: PaymentTypeCode,
    paymentMethodType?: PaymentMethodTypeCode,
    paymentKey?: string,
    status?: PaymentStatusCode,
    productName?: string,
    amount?: number,
    taxFreeAmount?: number,
    taxAmount?: number,
    tid?: string,
    ipAddress?: string,
    viverPayPaymentMethodId?: number,
    transactionAt?: string,
    errorCode?: number,
    errorMessage?: string,
    cancelErrorCode?: string,
    cancelErrorMessage?: string,
    canceledAt?: string,
    cancelAmount?: number,
    cancelCid?: string,
    cancelTid?: string,
    cancelIpAddress?: string,
    requestAt?: string,
    accountNumber?: string,
    accountName?: string,
    accountHolder?: string,
    bankCode?: string,
    bankName?: string,
    issueTid?: string,
    cardCompanyCode?: string,
    cardNo?: string,
    installMonth?: number,
    expireAt?: string

}

export const enum PaymentMethodTypeGroupCode {
    CARD = 'CARD',
    CASH = 'CASH',
    EASY_PAY = 'EASY_PAY',
    NOT_VIVER = 'NOT_VIVER'
}

export declare interface MethodTypesResponse {
    methodTypeGroup: PaymentMethodTypeGroupCode
    methodTypes: PaymentMethodTypeCode[]
}