import { cancelPointSchedule, postPointSchedule, putPointSchedule } from "apis/pointAPI"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { PointScheduleListItemType } from "types/PointScheduleListItemType"
import { initialPointScheduleState, parsePointScheduleBody, validatePintScheduleForm } from "./utils"

export type EditablePointScheduleListItemType = Omit<PointScheduleListItemType, "updatedAt" | "createdAt">

export type PointScheduleStateKeyType = keyof typeof initialPointScheduleState
export type PointScheduleStateValueType = typeof initialPointScheduleState[PointScheduleStateKeyType]

export type PointScheduleEditorType = "EDIT" | "CREATE"

type Props = {
  editorType: PointScheduleEditorType
  editingPointSchedule?: PointScheduleListItemType
  onFinishRequest: () => void
}

export const useEditPointScheduleData = ({ editingPointSchedule, editorType, onFinishRequest }: Props) => {
  const [pointScheduleState, setPointScheduleState] = useState<EditablePointScheduleListItemType>(
    editingPointSchedule || initialPointScheduleState
  )

  useEffect(() => {
    setPointScheduleState(editingPointSchedule || initialPointScheduleState)
  }, [editingPointSchedule])

  const changePointScheduleState = (key: PointScheduleStateKeyType, value: PointScheduleStateValueType) => {
    setPointScheduleState((prev) => ({ ...prev, [key]: value }))
  }

  const onCancel = (pointScheduleId: number) => {
    if (window.confirm("포인트 지급 예약을 취소하시겠습니까?")) {
      cancelPointSchedule(pointScheduleId)
        .then(() => onFinishRequest())
        .catch(() => toast.error("예약 취소에 실패했습니다."))
    }
  }

  const onSubmit = () => {
    if (!validatePintScheduleForm(pointScheduleState)) {
      return
    }

    if (editorType === "CREATE") {
      postPointSchedule(parsePointScheduleBody(pointScheduleState))
        .then(() => {
          toast.success("포인트 지급 예약이 완료되었습니다.")
          onFinishRequest()
          setPointScheduleState(initialPointScheduleState)
        })
        .catch(() => toast.error("포인트 지급 예약에 실패했습니다."))
    }

    if (editorType === "EDIT") {
      putPointSchedule(parsePointScheduleBody(pointScheduleState), pointScheduleState.id)
        .then(() => {
          toast.success("포인트 지급 예약이 수정되었습니다.")
          onFinishRequest()
          setPointScheduleState(initialPointScheduleState)
        })
        .catch(() => toast.error("포인트 예약 수정에 실패했습니다."))
    }
  }

  return {
    pointScheduleState,
    changePointScheduleState,
    onSubmit,
    onCancel,
  }
}
