import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material"
import { useEffect, useReducer } from "react"
import BuyOrderShippingTable from "./BuyOrderShippingTable"
import { PageType } from "types/PageType"
import { BuyOrderListSearchParams, BuyOrderStatusCode, BuyOrderType } from "types/BuyOrderType"
import { useSearchParams } from "react-router-dom"
import { DateTime } from "luxon"
import { CountType } from "types/CountType"
import Counters from "../SellOrder/Counters"
import { DesktopDatePicker } from "@mui/lab"
import ViewBuyOrderPopup from "../BuyOrder/ViewBuyOrderPopup"
import { countBuyOrders, getBuyOrderShipping } from "apis/buyOrderAPI"
import { toApplyCounts } from "utils/SellOrderTypeConverter"
import ShippingRequestPopup from "components/Popup/ShippingRequestPopup"
import { SaleTypeCode } from "types/ProductType"
import { getShippings } from "../BuyOrder/utils"

export interface BuyOrderState {
  selectedIds: string[]
  buyOrders: PageType<BuyOrderType>
  buyOrderCount: CountType[]
  isShowViewPopup: boolean
  isShippingRequestPopupOpen: boolean
  editBuyOrder?: BuyOrderType
  startDateFilter?: DateTime
  endDateFilter?: DateTime
  searchFilterKey?: keyof BuyOrderListSearchParams
  searchFilterValue?: string
  refresh: boolean
  loading: boolean
}

const initState: BuyOrderState = {
  selectedIds: [],
  buyOrders: {
    content: [],
    pageable: { pageSize: 25, pageNumber: 0 },
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  },
  buyOrderCount: [],
  isShowViewPopup: false,
  isShippingRequestPopupOpen: false,
  editBuyOrder: undefined,
  startDateFilter: undefined,
  endDateFilter: undefined,
  searchFilterKey: "productId",
  searchFilterValue: undefined,
  refresh: false,
  loading: false,
}

interface BuyOrderAction {
  type: string
  payload?: any
}

function reducer(state: BuyOrderState, action: BuyOrderAction): BuyOrderState {
  switch (action.type) {
    case "SELECTION_BUYORDER_IDS":
      return { ...state, selectedIds: action.payload }
    case "FETCH_BUYORDER":
      return { ...state, buyOrders: action.payload }
    case "FETCH_BUYORDER_COUNT":
      return { ...state, buyOrderCount: action.payload }
    case "CHANGE_START_DATE":
      return { ...state, startDateFilter: action.payload }
    case "CHANGE_END_DATE":
      return { ...state, endDateFilter: action.payload }
    case "SHOW_VIEW_BUYORDER_POPUP":
      return { ...state, isShowViewPopup: true, editBuyOrder: action.payload }
    case "HIDE_VIEW_BUYORDER_POPUP":
      return { ...state, isShowViewPopup: false }
    case "SHOW_SHIPPING_REQUEST_POPUP_OPEN":
      return { ...state, isShippingRequestPopupOpen: true }
    case "HIDE_SHIPPING_REQUEST_POPUP_OPEN":
      return { ...state, isShippingRequestPopupOpen: false }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: "" }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
    case "SEARCH":
      return { ...state, refresh: !state.refresh }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }

  return state
}

const BuyOrderShippings = () => {
  const productSaleType: SaleTypeCode[] = [
    SaleTypeCode.직접판매,
    SaleTypeCode.위탁판매,
    SaleTypeCode.진단전판매,
    SaleTypeCode.매입보증판매,
  ]
  const buyOrderStatus: BuyOrderStatusCode[] = [
    BuyOrderStatusCode.배송_준비,
    BuyOrderStatusCode.배송중,
    BuyOrderStatusCode.구매_완료,
  ]

  const [state, dispatch] = useReducer(reducer, initState)
  const [currentSearchParams] = useSearchParams()

  const fetchBuyOrderShippings = (searchParams: BuyOrderListSearchParams = {}) => {
    let newParams: BuyOrderListSearchParams = {
      ...searchParams,
      productSaleType: productSaleType,
      status: (currentSearchParams.get("status")?.split(",") as BuyOrderStatusCode[]) || buyOrderStatus,
      productId: currentSearchParams.get("productId") ?? undefined,
      buyerId: currentSearchParams.get("buyerId") ?? undefined,
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate(),
      sellerId: currentSearchParams.get("sellerId") ?? undefined,
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    dispatch({ type: "START_LOADING" })
    getBuyOrderShipping(newParams).then((buyOrders) => dispatch({ type: "FETCH_BUYORDER", payload: buyOrders }))
        .finally(() => dispatch({ type: "END_LOADING" }))
  }

  const fetchSellOrderCounts = () => {
    countBuyOrders({
      ...currentSearchParams,
      productSaleType: productSaleType,
      status: buyOrderStatus,
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate(),
      isShippingView:true
    }).then((result) => {
      dispatch({
        type: "FETCH_BUYORDER_COUNT",
        payload: toApplyCounts(result, buyOrderStatus || [], "."),
      })
    })
  }

  const handleOutgoingShippingClick = () => {
    dispatch({ type: "SHOW_SHIPPING_REQUEST_POPUP_OPEN" })
  }

  useEffect(() => {
    fetchBuyOrderShippings({
      page: state.buyOrders.pageable?.pageNumber || 0,
      size: state.buyOrders.pageable?.pageSize || 25,
    })
    fetchSellOrderCounts()
  }, [state.refresh, currentSearchParams])

  return (
    <Grid container spacing={2}>
      <ViewBuyOrderPopup
        open={state.isShowViewPopup}
        selectedBuyOrderId={state.editBuyOrder?.id}
        handleClose={() => {
          dispatch({ type: "HIDE_VIEW_BUYORDER_POPUP" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <ShippingRequestPopup
        open={state.isShippingRequestPopupOpen}
        type={"NORMAL"}
        selectedShipping={getShippings(state.buyOrders.content, state.selectedIds)}
        handleClose={() => {
          dispatch({ type: "HIDE_SHIPPING_REQUEST_POPUP_OPEN" })
          dispatch({ type: "REFRESH" })
        }}
      />
      {
        <Grid item xs={12}>
          <Counters params={state.buyOrderCount} selectedLabel={currentSearchParams.get("status")} />
        </Grid>
      }
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12}>
            <Stack spacing={1} direction={"column"}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                  <FormControl sx={{ width: "160px" }} size={"small"}>
                    <InputLabel id="search_key">검색항목</InputLabel>
                    <Select
                      value={state.searchFilterKey || ""}
                      onChange={(params) => {
                        dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                      }}
                    >
                      <MenuItem value={"id"}>구매주문번호</MenuItem>
                      <MenuItem value={"productId"}>상품번호</MenuItem>
                      <MenuItem value={"productTitle"}>상품명</MenuItem>
                      <MenuItem value={"buyerName"}>구매자 이름</MenuItem>
                      <MenuItem value={"buyerPhone"}>구매자 연락처</MenuItem>
                      <MenuItem value={"sellerId"}>판매자 ID</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: "220px" }}
                    size={"small"}
                    value={state.searchFilterValue || ""}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch({ type: "SEARCH" })
                      }
                    }}
                    onChange={(params) => {
                      dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: params.target.value })
                    }}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} sx={{ lineHeight: "45px" }}>
                    <DesktopDatePicker
                      label="구매 시작일"
                      inputFormat="yyyy-MM-dd"
                      value={state.startDateFilter ?? null}
                      onChange={(params) => {
                        dispatch({ type: "CHANGE_START_DATE", payload: params })
                        dispatch({ type: "REFRESH" })
                      }}
                      renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                    />
                    <DesktopDatePicker
                      label="구매 종료일"
                      inputFormat="yyyy-MM-dd"
                      value={state.endDateFilter ?? null}
                      onChange={(params) => {
                        dispatch({ type: "CHANGE_END_DATE", payload: params })
                        dispatch({ type: "REFRESH" })
                      }}
                      renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                    />
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <BuyOrderShippingTable
              state={state}
              dispatch={dispatch}
              fetch={fetchBuyOrderShippings}
              components={[
                <Button
                  variant={"contained"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={handleOutgoingShippingClick}
                >
                  배송 요청
                </Button>,
              ]}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default BuyOrderShippings
