import {httpGet, httpPost} from "../utils/httpAPI"
import {BuyOrderType} from "types/BuyOrderType"
import {CountType} from "types/CountType"
import {PageType} from "types/PageType"

export const countBuyOrders: (searchParams: any) => Promise<CountType[]> = (searchParams) =>
  httpGet("/buy-orders/counts", searchParams)
export const getBuyOrders: (searchParams: any) => Promise<PageType<BuyOrderType>> = (searchParams) =>
  httpGet("/buy-orders", searchParams)
export const getBuyOrderList: (searchParams: any) => Promise<BuyOrderType[]> = (searchParams) =>
  httpGet("/buy-orders/list", searchParams)
export const getRefundBuyOrders: (searchParams: any) => Promise<PageType<BuyOrderType>> = (searchParams) =>
  httpGet("/buy-orders/refunds", searchParams)
export const getBuyOrder: (sid: string) => Promise<BuyOrderType> = (sid) => httpGet(`/buy-orders/${sid}`)

export const requestRefund: (sid: string) => Promise<BuyOrderType> = (sid: string) =>
  httpPost(`/buy-orders/${sid}/refunds`)

export const approveRefund: (sid: string) => Promise<BuyOrderType> = (sid: string) =>
  httpPost(`/buy-orders/${sid}/refunds/approve`)

export const rejectRefund: (sid: string) => Promise<BuyOrderType> = (sid: string) =>
  httpPost(`/buy-orders/${sid}/refunds/reject`)

export const directPickUp: (sid: string) => Promise<BuyOrderType> = (sid: string) =>
  httpPost(`/buy-orders/${sid}/direct-pick-up`)

export const getBuyOrderShipping: (searchParams:any)  => Promise<PageType<BuyOrderType>> = (searchParams) =>
  httpGet("/buy-orders/shipping", searchParams)
