import React, { useEffect, useState } from "react"
import { Button, MenuItem, Select, TextField } from "@mui/material"
import { requestShipping, updateShippingMethod, updateTrackingNo } from "../../../../apis/shippingAPI"
import { toast } from "react-toastify"
import { BuyOrderStatusCode, BuyOrderType } from "types/BuyOrderType"
import { directPickUp } from "../../../../apis/buyOrderAPI"
import { ShippingRequestType } from "types/ShippingType"
import { ShippingMethod } from "../../../../types/ShippingMethod"
import ChangeShippingAddress from "../../../../components/ChangeShippingAddress/ChangeShippingAddress"

type Props = {
  buyOrder?: BuyOrderType
  setBuyOrder: Function
  handleClose: Function
}

const OutgoingShipping = ({ buyOrder, setBuyOrder, handleClose }: Props) => {
  const [trackingNo, setTrackingNo] = useState<string>()
  const [shippingMethod, setShippingMethod] = useState<ShippingMethod | undefined>(buyOrder?.shipping?.shippingMethod)
  const [shippingAddress, setShippingAddress] = useState<String>(`${buyOrder?.shipping?.address || ""} ${buyOrder?.shipping?.addressDetail || ""}`)

  useEffect(() => {
    setShippingMethod(buyOrder?.shipping?.shippingMethod)
    setShippingAddress(`${buyOrder?.shipping?.address || ""} ${buyOrder?.shipping?.addressDetail || ""}`)
  }, [buyOrder?.shipping?.address])

  const updateShipping = (event: any) => {
    if (buyOrder?.shipping?.id && trackingNo) {
      updateTrackingNo(buyOrder?.shipping?.id, { trackingNo: trackingNo }).catch((error) => {
        toast.error(`운송장번호 갱신 실패 (${error.message || error})`)
      })
    } else {
      toast.error(`배송정보를 찾을 수 없습니다. 관리자에게 문의하세요.`)
    }
  }

  const handleRequestShipping = (manually: boolean = false) => {
    if (
      window.confirm(`구매주문번호 [${buyOrder?.shipping?.id}]를 [${manually ? "직접 " : "배송사에 "} 배송 요청]하시겠습니까?`)
    ) {
      const req: ShippingRequestType = { items: [{ id: buyOrder?.shipping?.id }], manually: manually }

      requestShipping(req).then((res) => {
        let msg = `* 성공 ${res.succeed?.length || 0}건\n`
        msg += res.succeed?.map((it) => `${it.id}`)?.join("\n") || ""
        msg += "\n"
        msg += `* 실패 ${res.failure?.length || 0}건\n`
        msg += res.failure?.map((it) => `${it.id} (${it.code}: ${it.message})`)?.join("\n") || ""
        alert(msg)
        handleClose()
      })
    }
  }

  const handleDirectPickUp = () => {
    if (
      window.confirm(
        "배송사를 통하지 않고 쇼룸 등을 통한 고객 본인 직접 수령시에만 사용하여야 합니다.\n[본인 직접 수령] 처리하시겠습니까?",
      )
    ) {
      directPickUp(buyOrder?.id!).then((res) => {
        alert(`${buyOrder?.id} 직접 수령 완료`)
        setBuyOrder(res)
      })
    }
  }

  const handleSetAddress = (address: string) => {
    setShippingAddress(address)
  }

  const handleShippingMethod = () => {
    const shippingId = buyOrder?.shipping?.id

    if (window.confirm(`구매주문번호 [${buyOrder?.shipping?.id}]의 배송 방식을 ${shippingMethod}로 변경하시겠습니까?`)) {
      if (typeof shippingId !== "undefined" && typeof shippingMethod !== "undefined") {
        updateShippingMethod(shippingId, shippingMethod).then((res) => {
          setBuyOrder({
            ...buyOrder,
            shipping: res,
          })
        }).catch((ex) => {
          toast.error(ex.message)
        })
      }
    }
  }

  return (
    <>
      <h3>출고 배송정보</h3>
      <table className={"basic"}>
        <tbody>
        <tr>
          <td className={"header"} colSpan={3}>
            운송장 번호
          </td>
          <td className={"header"}>배송상태</td>
          <td className={"header"}>배송타입</td>
          <td className={"header"} colSpan={2}>수령방법</td>
          <td className={"header"}>이름</td>
          <td className={"header"}>연락처</td>
          <td className={"header"} colSpan={3}>
            배송지 주소
            <ChangeShippingAddress
              disabled={buyOrder?.buyOrderStatus === undefined || !([BuyOrderStatusCode.결제_대기, BuyOrderStatusCode.상품_준비, BuyOrderStatusCode.진단중].includes(buyOrder?.buyOrderStatus!!))}
              shippingId={buyOrder?.shipping?.id}
              onUpdateAddress={handleSetAddress}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <TextField
              placeholder={"운송장번호"}
              size={"small"}
              value={trackingNo ?? buyOrder?.shipping?.trackingNo}
              onChange={(event) => setTrackingNo(event.target.value)}
            />
            <Button variant={"outlined"} onClick={updateShipping}>
              {(trackingNo ?? buyOrder?.shipping?.trackingNo) ? "변경" : "입력"}
            </Button>
          </td>
          <td>{buyOrder?.shipping?.shippingStatus}</td>
          <td>{buyOrder?.shipping?.shippingType}</td>
          <td colSpan={2}>
            <Select<ShippingMethod>
              size={"small"}
              value={shippingMethod || ""}
              onChange={(params) => {
                setShippingMethod(params.target.value as ShippingMethod)
              }}
            >
              <MenuItem value={"안전배송"}>발렉스</MenuItem>
              <MenuItem value={"안전배송_2"}>일양택배</MenuItem>
              <MenuItem value={"쇼룸방문"}>쇼룸방문</MenuItem>
              <MenuItem value={"일반택배"}>일반택배</MenuItem>
            </Select>
            <Button variant={"outlined"} onClick={handleShippingMethod} disabled={buyOrder?.shipping?.shippingMethod === shippingMethod}>
              수정
            </Button>
          </td>
          <td>{buyOrder?.shipping?.name}</td>
          <td>{buyOrder?.shipping?.phone}</td>
          <td colSpan={3}>
            {shippingAddress}
          </td>
        </tr>
        <tr>
          <td className={"header"} colSpan={12}>
            배송 추적
          </td>
        </tr>
        {buyOrder?.shipping?.journey?.length ? (
          buyOrder?.shipping?.journey?.map((it) => (
            <tr>
              <td colSpan={4}>{it.when}</td>
              <td colSpan={4}>{it.how}</td>
              <td colSpan={4}>{it.where}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={12}>배송정보가 없습니다</td>
          </tr>
        )}
        <tr>
          <td className={"no-border"} style={{ textAlign: "end" }} colSpan={12}>
            <Button
              size={"medium"}
              disabled={buyOrder?.shipping?.shippingStatus !== "배송 준비"}
              variant={"contained"}
              onClick={() => handleDirectPickUp()}
            >
              본인 직접 수령
            </Button>
            &nbsp;
            <Button
              size={"medium"}
              disabled={buyOrder?.shipping?.shippingStatus !== "배송 준비"}
              variant={"contained"}
              onClick={() => handleRequestShipping(true)}
            >
              직접 출고 배송요청
            </Button>
          </td>
        </tr>
        </tbody>
      </table>

    </>
  )
}

export default OutgoingShipping
